<div class="modal fade" style="background: rgba(0, 0, 0, 0.5);" id="modalFormReserva" aria-hidden="true" aria-labelledby="exampleModalToggleLabel" tabindex="-1">
  <div class="modal-dialog modal-xl modal-dialog-centered">
    <div class="modal-content" style="background: rgba(0, 101, 159, 0.93) ;">
      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
     
      <app-reserva-principal style="margin-top: -30px;"></app-reserva-principal>

    </div>
  </div>
</div>

