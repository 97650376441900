import { Component, OnInit } from '@angular/core';
import { ReservacionTour } from './../../models/reservacion.model';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-paquete-info',
  templateUrl: './paquete-info.component.html',
  styleUrls: ['./paquete-info.component.css']
})
export class PaqueteInfoComponent implements OnInit {


  //Obtenemos la fecha minima
  getFechaActual: string = this.localData.getFechaActual();
  //Obtenemos la fecha actual
  getFechaMinima: string = this.localData.getFechaSiguiente(this.localData.getFechaActual());
  //Creamos un observable que nos mandará información actualizada del tour filtrado
  detalleTourPaquete$: Observable<any>;
  //Id del destino que se desea filtrar
  idDestino: number = 0;
  //Datos del paquete o tour
  dataPaqueteTour: any;
  //Información detallada del paquete o tour
  dataDescripcionPaqueteTour: any;
  dataDetallePaqueteTour: any;
  dataIncluyePaqueteTour: any;
  dataTransportePaqueteTour: any;
  costoTour: number = 0;
  //Modelo para hacer el modelado del formulario
  formReservacion: ReservacionTour;
  //Variable que guarda los txt para nombramiento
  dataTxtInfo: any;
  //Iniciar recarga de pagina
  public apiUrl: any = this.externalData._apirest;

  public showPrice: any;


  constructor( private localData: LocalDataServiceService,
    private externalData: ExternalDataServiceService,
    private _route: ActivatedRoute,
    private router: Router ) {
      this.idDestino =  parseInt(this._route.snapshot.paramMap.get('id')!);
      this.dataPaqueteTour = '';
      this.dataDescripcionPaqueteTour = '';
      this.dataDetallePaqueteTour = [];
      this.dataIncluyePaqueteTour = [];
      this.dataTransportePaqueteTour = [];
      this.dataTxtInfo = '';
      this.detalleTourPaquete$ = this.externalData.postGetDetalleTourPaquete( this.idDestino , 'PAQUETE' );
      this.formReservacion = new ReservacionTour();
      this.formReservacion.Fecha = this.getFechaMinima;
  }

  ngOnInit(): void {
    this.getDatosTourPaquete();
    this.getTxtDetalle();
    this.subirScroll();
    this.showPrice = this.externalData._Configuracion_;
  }

  getDatosTourPaquete() {
    this.detalleTourPaquete$.subscribe( (data: any) => {
      this.costoTour = data.tour[0].Costo;
      this.dataPaqueteTour = data.tour[0];
      this.formReservacion.Txt_Destino = this.dataPaqueteTour.title;
      this.formReservacion.Img = this.dataPaqueteTour.img;
      this.dataDescripcionPaqueteTour = data.detalle.filter( (datos: any) => datos.TipoDetalle == 'descripcion' )[0];
      this.dataDetallePaqueteTour = data.detalle.filter( (datos: any) => datos.TipoDetalle == 'detalle' );
      this.dataIncluyePaqueteTour = data.detalle.filter( (datos: any) => datos.TipoDetalle == 'incluye_tour' );
      this.dataTransportePaqueteTour = data.detalle.filter( (datos: any) => datos.TipoDetalle == 'incluye_transporte' );
    });
  }

  sumarPersonas(): number {
    return this.localData.sumarDosValores( this.formReservacion.Adultos , this.formReservacion.Ninos );
  }

  getTxtDetalle() {
    this.localData.getInfoTourPaquete().subscribe(( data:any ) => {
      this.dataTxtInfo = data;
    });
  }

  actualizarFecha( valor: any ) {
    const fecha = valor.target.value;
    if( fecha <= this.getFechaActual ) {
      this.formReservacion.Fecha = this.getFechaMinima;
    } else {
      this.formReservacion.Fecha = fecha;
    }
  }

  enviarFormulario() {

    this.formReservacion.Costo = this.costoTour;
    this.formReservacion.Costo_Total = this.costoTour  * this.sumarPersonas();
    this.formReservacion.Tipo = 'PAQUETE';
    this.formReservacion.Id = this.idDestino;
    this.formReservacion.Idioma = this.localData.idiomaActual;
    this.localData.prospectoTourDestino = this.formReservacion;

    this.router.navigate(['/destination-reservation']);

  }

  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 2000);
  }

  getImagen( path: string ): string {
    return  path == 'no-image' ? `assets/img/no-image.png` : `${this.apiUrl}/${path}`;
  }

  calculatePrice(): number {
    return this.sumarPersonas() *  this.costoTour;
  }

}
