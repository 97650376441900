import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { consultaReserva } from './../../models/consulta-reservacion.model';
import { environment } from 'src/environments/environment.prod';


const base_url = environment.urlrest;
const idioma = environment.idioma;


@Component({
  selector: 'app-my-reservation',
  templateUrl: './my-reservation.component.html',
  styleUrls: ['./my-reservation.component.css']
})
export class MyReservationComponent implements OnInit {

  // Variable que almacena los textos en idioma español/ingles
  public txtForm: any;
  //Variable que indica que el resultado es correcto
  public resultadoCorrecto: boolean = false;
  //Mostrar alerta de error
  public mostrarAlerta: boolean = false;
  //Variable para el modelo de la consulta de reservacion
  public _creserva_: consultaReserva;
  //Datos de reservacion
  public dataReservacion: any;

  @ViewChild( 'dataImprimir', {static: false}) el!: ElementRef;

  constructor( private externalData: ExternalDataServiceService,
               private localData: LocalDataServiceService ) {
    this.txtForm = [];
    this.dataReservacion = [];
    this._creserva_ = new consultaReserva();
  }

  ngOnInit(): void {
    this.obtenerTxtInglesEspañol();
    this.subirScroll();
  }

  exportarPdf() {
    const url = `${base_url}/formats/pdf/${idioma}_reservacion.php?Id=${this._creserva_.Id}&Correo=${this._creserva_.Correo}&Telefono=${this._creserva_.Telefono}`;
    window.open(url, '_blank');
  }

  obtenerTxtInglesEspañol() {
    this.localData.getTxtMyReservation().subscribe((data: any) => {
      this.txtForm = data;
    });
  }

  obtenerDatosReservacion() {

    this.localData.isLoading = true;

    this.externalData.postGetOnlyReservacion( this._creserva_ ).subscribe((data: any) => {

      if( data.ok ) {
        this.dataReservacion = data.reserva[0];
        this.mostrarAlerta = false;
        this.resultadoCorrecto = true;
       // console.log(this.dataReservacion);
      } else {
        this.resultadoCorrecto = false;
        this.mostrarAlerta = true;
      }

      this.subirScroll();

    });

  }

  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 2000);
  }


}
