<div id="carouselExampleDark" class="carousel carousel-dark slide" data-bs-ride="carousel">
  <div class="carousel-inner">

    <div *ngFor="let d of datosDelBanner" class="carousel-item {{d.class}}"  data-bs-interval="5000">
      <img [src]="getImg(d.img)" class="d-block w-100" >
      <div class="carousel-caption">
        <p class="txt-banner-grl" >{{ d.txt }} <br> {{ d.txtDos }} <br> {{ d.txtTres }}</p>
        <p><button (click)="ejecutaFuncion()" class="btn btn-lg btn-primary btntxt-banner-grl" href="#" *ngIf="d.verBtn==='SI'"> {{ d.btn }} </button></p>
      </div>
    </div>

  </div>
  <button style="margin-top: 20%; width: 5%;height: 12%;background:#156ba3;color: #fff !important" class="carousel-control-prev" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="prev">
    <span class="material-icons" aria-hidden="true" style="color: #fff;">arrow_back_ios</span>
    <span  class="visually-hidden">Previous</span>
  </button>
  <button style="margin-top: 20%; width: 5%;height: 12%;background:#156ba3;"  class="carousel-control-next" type="button" data-bs-target="#carouselExampleDark" data-bs-slide="next">
    <span class="material-icons" aria-hidden="true" style="color: #fff;">arrow_forward_ios</span>
    <span class="visually-hidden">Next</span>
  </button>
</div>
