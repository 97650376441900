
<div style="width: 100%;height:45vh;position: relative;margin-top: 3.5em;" >
    <h1 *ngIf="idioma=='es'"  style=" background: rgba(44, 44, 44, 0.1); padding-left: 1em;padding-right: 1em;padding-top: .1em; padding-bottom: .5em; position: absolute;font-size: 3em;color: #fff;font-family: Pacifico; bottom: 1.5em;left: 10%;" >Aviso de <br> privacidad</h1>
    <h1 *ngIf="idioma=='en'"  style=" background: rgba(44, 44, 44, 0.1); padding-left: 1em;padding-right: 1em;padding-top: .1em; padding-bottom: .5em; position: absolute;font-size: 3em;color: #fff;font-family: Pacifico; bottom: 1.5em;left: 10%;" >Privacy <br> notice</h1>
    <img src="assets/img/privacidad.jpg " style="height: 100%; width: 100%;">
</div>

<div class="container-lg" style="padding-top: 1.5em;padding-bottom: 3em;font-family: Champagne;text-align: justify;">
    <p *ngIf="idioma=='es'">
        A los Usuarios (como se definen posteriormente), les informamos que el siguiente Aviso de Privacidad,les es aplicable por el simple uso o acceso a cualquiera de las páginas, aplicaciones web y móviles,
        softwares y, aplicaciones en general, que integran el Portal de https://eitur.mx (en adelante y, conjunta e
        indistintamente, el "Portal"), por lo que entenderemos que lo acepta y acuerda en obligarse en su
        cumplimiento. <span> En caso de que no esté de acuerdo con el Aviso de Privacidad y/o con los
        Términos y Condiciones a su disposición, deberá abstenerse de acceder o utilizar el Portal.</span>
    </p>

    <p *ngIf="idioma=='es'">
        El Usuario, entendido como aquella persona que realiza el uso o accede, mediante equipo de cómputo y/o
        cualquier equipo de comunicación o dispositivo, al Portal (en adelante el “Usuario”), acepta plena y sin
        reserva todas y cada una de las disposiciones incluidas en el presente Aviso de Privacidad.
    </p>

    <p *ngIf="idioma=='es'">
        <span>RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES.</span> Para Servicios Especializados
        Turisticos Unlimited EITUR, S.A. de. C.V. (en adelante la “Empresa”) la seguridad de los Usuarios es
        nuestra prioridad, por lo que protegemos sus datos personales mediante el uso, aplicación y
        mantenimiento de altas medidas de seguridad técnicas, físicas y administrativas
    </p>

    <p *ngIf="idioma=='es'">
        Como Usuario, usted tiene la oportunidad de escoger entre una amplia gama de productos y servicios a
        través de nuestro Portal, sabiendo que sus datos personales estarán protegidos y serán tratados de
        manera confidencial. Les informamos que el RESPONSABLE de recabar y dar tratamiento y/o utilizar los
        datos personales que el Usuario proporcione a través del Portal, es la Empresa, así como sus subsidiarias,
        asociadas, sociedades controladoras y afiliadas
    </p>

    <p *ngIf="idioma=='es'">
        <span>DOMICILIO DEL RESPONSABLE</span>. Para efectos del presente aviso de privacidad, la Empresa señala,
        individualmente, como su domicilio, el ubicado en 90 X 71 Y 75 MZA.58 int. LOTE.9, REGION.228, Benito
        Juárez, Quintana Roo, C. P. 77516.
    </p>

    <p *ngIf="idioma=='es'">
        <span>DATOS PERSONALES QUE PUEDEN SER RECOLECTADOS.</span> Los datos personales que la Empresa
        puede recopilar del Usuario al utilizar el Portal y/o contratar nuestros servicios y productos, son los
        siguientes:
    </p>

    <p *ngIf="idioma=='es'">
        I.-   Nombre
    </p>

    <p *ngIf="idioma=='es'">
        II.-  Telefono
    </p>

    <p *ngIf="idioma=='es'">
        III.- Correo electrónico
    </p>

    <p *ngIf="idioma=='es'">
        IV.-  Pais de residencia
    </p>

    <p *ngIf="idioma=='es'">
        <span>FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES.</span> Los datos personales que la
        Empresa recabe serán utilizados para atender las siguientes finalidades:
    </p>

    <p *ngIf="idioma=='es'">
        I. Para garantizar la calidad de nuestros servicios Turisticos.
    </p>

    <p *ngIf="idioma=='es'">
        II. Para integrar expedientes, bases de datos y sistemas necesarios para llevar a cabo las operaciones y
        servicios correspondientes; (ii) Para reclamar la entrega de premios y/o promociones; (iii) Para llevar a
        cabo análisis internos;
    </p>


    <p *ngIf="idioma=='es'">
        De manera adicional, se podrán utilizar sus datos personales para las siguientes finalidades
        secundarias: (i) Mercadotecnia, publicidad y prospección comercial; (ii) Ofrecerle, en su caso, otros
        productos y servicios propios de la Empresa o de cualquiera de sus afiliadas, subsidiarias, sociedades
        controladoras, asociadas, comisionistas o sociedades; (iii) Remitirle promociones de otros bienes,
        servicios y/o productos; (iv) Para realizar análisis estadísticos, de generación de modelos de
        información y/o perfiles de comportamiento actual y predictivo y, (v) Para participar en encuestas,
        sorteos y promociones.

    </p>

    <p *ngIf="idioma=='es'">
        <span>OPCIONES Y MEDIOS PARA LIMITAR EL USO O DIVULGACIÓN DE LOS DATOS.</span> La Empresa tiene
        implementadas medidas de seguridad administrativas, técnicas y físicas para proteger sus datos
        personales, mismas que igualmente exigimos sean cumplidas por los proveedores de servicios que
        contratamos. Usted podrá limitar el uso o divulgación de sus datos personales enviando un correo
        electrónico a contact@eitur.mx indicándonos en el cuerpo del correo su nombre completo y que dato
        desea que no sea divulgado, de proceder su solicitud, se le registrará en el listado de exclusión.
    </p>

    <p *ngIf="idioma=='es'">
        <span>MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN, CANCELACIÓN U
        OPOSICIÓN (DERECHOS ARCO).</span> Puede enviar un correo electrónico a contact@eitur.mx, en cualquier
        momento, para ejercer sus Derechos de Acceso, Rectificación, Cancelación u Oposición (“Derechos
        ARCO”). Para ejercer los Derechos ARCO, usted (o su representante legal), deberá presentar la solicitud,
        identificándose con la siguiente documentación:
    </p>

    <p *ngIf="idioma=='es'">I. Nombre del usuario o titular.</p>

    <p *ngIf="idioma=='es'">II. Domicilio del usuario o titular u otro medio para comunicarle la respuesta a su solicitud.</p>

    <p *ngIf="idioma=='es'">III. Documentos que acrediten su identidad (IFE/INE o pasaporte) y, en su caso, los documentos que
        acrediten la representación legal del solicitante.
    </p>

    <p *ngIf="idioma=='es'">IV. Una descripción de la información / los datos sobre los que está tratando de ejercer sus derechos
        ARCO y los derechos que le gustaría ejercer.
    </p>

    <p *ngIf="idioma=='es'">
        Si uno o más de los documentos mencionados anteriormente no están incluidos, y/o los documentos
        tienen errores u omisiones, la Empresa le notificará dentro de los 3 días hábiles posteriores a la recepción
        de la solicitud y le pedirá los documentos faltantes y/o las correcciones pertinentes; tendrá 5 días hábiles
        a partir de esa notificación para proporcionar la información actualizada, de lo contrario, la solicitud se
        entenderá como no presentada.
    </p>

    <p *ngIf="idioma=='es'">
        <span>TRANSFERENCIA DE DATOS PERSONALES.</span> La Empresa podrá divulgar sus datos personales a
        aquellos terceros que, en virtud de los servicios y productos ofrecidos, necesiten conocerlos para cumplir
        cabalmente con los mismos.
    </p>


    <p *ngIf="idioma=='es'">
        Asimismo, la Empresa puede divulgar su información a las autoridades competentes en términos de la
        legislación aplicable; cualquier transferencia de sus datos personales sin consentimiento se realizará de
        acuerdo con el Artículo 37 de la LFPDPPP.
    </p>

    <p *ngIf="idioma=='es'">
        <span>WEB BEACONS.</span> La Empresa, podrá o no, utilizar tecnologías de seguimiento tales como web beacons,
        similares a las cookies, para recabar datos sobre sus visitas en el Portal; éstas son pequeñas imágenes
        electrónicas incrustadas en el contenido web o mensajes de correo electrónico, las cuales no se
        encuentran normalmente visibles para los Usuarios y que nos permiten generar contenidos casi
        personalizados para ofrecerle una mejor experiencia cuando utilice nuestro Portal.
    </p>

    <p *ngIf="idioma=='es'">
        En caso de no estar de acuerdo con cualquiera de las condiciones aquí establecidas, el Usuario siempre
        podrá cambiar la configuración de su navegador.
    </p>

    <p *ngIf="idioma=='es'">
        <span>MODIFICACIONES AL AVISO DE PRIVACIDAD.</span> El presente aviso de privacidad puede sufrir
        modificaciones, cambios o actualizaciones derivadas, entre otras cosas, por nuevos requerimientos
        legales; necesidades propias de la Empresa, por los productos o servicios que ofrecemos; por nuestras
        prácticas de privacidad; por cambios en nuestro modelo de negocio, o por otras causas
    </p>

    <p *ngIf="idioma=='es'">
        Cualquier modificación al presente aviso de privacidad le será notificada a través de cualquiera de los
        siguientes medios: un comunicado por escrito enviado a su domicilio; por el correo electrónico que señale;
        un mensaje a su teléfono móvil; un mensaje dado a conocer a través del Portal o de cualquier medio
        electrónico que utilice para celebrar operaciones; o bien, en periódicos de amplia circulación el domicilio
        social de la Empresa.
    </p>


    <!-- IDIOMA INGLES -->

    <p *ngIf= "idioma == 'en'">
        To the Users (as defined below), we inform them that the following Privacy Notice is applicable to them for the simple use or access to any of the pages, web and mobile applications,
        softwares and applications in general, that make up the https://eitur.mx Portal (hereinafter and, jointly and
        indistinctly, the "Portal"), so we understand that you accept it and agree to be bound by your
        compliance. <span> In case you do not agree with the Privacy Notice and / or with the
        Terms and Conditions at your disposal, you must refrain from accessing or using the Portal. </span>
    </p>

    <p *ngIf= "idioma == 'en'">
        The User, understood as the person who uses or accesses, through computer equipment and / or
        any communication equipment or device, to the Portal (hereinafter the "User"), fully and without
        reserves each and every one of the provisions included in this Privacy Notice.
    </p>

    <p *ngIf= "idioma == 'en'">
        <span> RESPONSIBLE FOR THE TREATMENT OF YOUR PERSONAL DATA. </span> For Specialized Services
        Turisticos Unlimited EITUR, S.A. from. CV. (hereinafter the "Company") the security of users is
        our priority, so we protect your personal data through the use, application and
        maintenance of high technical, physical and administrative security measures
    </p>

    <p *ngIf= "idioma == 'en'">
        As a User, you have the opportunity to choose from a wide range of products and services to
        through our Portal, knowing that your personal data will be protected and will be treated as
        confidential manner. We inform you that the RESPONSIBLE for collecting and treating and / or using the
        personal data that the User provides through the Portal, is the Company, as well as its subsidiaries,
        associates, holding companies and affiliates
    </p>

    <p *ngIf = "idioma == 'en'">
        <span> ADDRESS OF THE RESPONSIBLE </span>. For the purposes of this privacy notice, the Company indicates,
        individually, as their address, the one located at 90 X 71 Y 75 MZA. 58 int. LOT. 9, REGION. 228, Benito
        Juárez, Quintana Roo, C. P. 77516.
    </p>

    <p *ngIf = "idioma == 'en'">
        <span> PERSONAL DATA THAT MAY BE COLLECTED. </span> The personal data that the Company
        can collect from the User when using the Portal and / or contracting our services and products, are the
        following:
    </p>

    <p *ngIf = "idioma == 'en'">
        I.- Name
    </p>

    <p *ngIf = "idioma == 'en'">
        II.- Telephone
    </p>

    <p *ngIf = "idioma == 'en'">
        III.- Email
    </p>

    <p *ngIf = "idioma == 'en'">
        IV.- Country of residence
    </p>

    <p *ngIf = "idioma == 'en'">
        <span> PURPOSES OF THE PROCESSING OF YOUR PERSONAL DATA. </span> The personal data that the
        Company collect will be used to serve the following purposes:
    </p>

    <p *ngIf = "idioma == 'en'">
        I. To guarantee the quality of our Tourist services.
    </p>

    <p *ngIf = "idioma == 'en'">
        II. To integrate files, databases and systems necessary to carry out operations and
        corresponding services; (ii) To claim the delivery of prizes and / or promotions; (iii) To carry
        carry out internal analysis;
    </p>


    <p *ngIf = "idioma == 'en'">
        Additionally, your personal data may be used for the following purposes
        Secondary: (i) Marketing, advertising and commercial prospecting; (ii) Offer you, where appropriate, other
        products and services of the Company or of any of its affiliates, subsidiaries, companies
        holding companies, associates, commission agents or companies; (iii) Send you promotions of other goods,
        services and / or products; (iv) To perform statistical analysis, to generate models of
        information and / or profiles of current and predictive behavior and, (v) To participate in surveys,
        giveaways and promotions.

    </p>

    <p *ngIf = "idioma == 'en'">
        <span> OPTIONS AND MEANS TO LIMIT THE USE OR DISCLOSURE OF DATA. </span> The Company has
        administrative, technical and physical security measures in place to protect your data
        personal data, which we also require to be complied with by the service providers that
        we hire. You can limit the use or disclosure of your personal data by sending an email
        email to contact@eitur.mx indicating your full name and information in the body of the email
        you want it not to be disclosed, if your request proceeds, you will be registered in the exclusion list.
    </p>

    <p *ngIf = "idioma == 'en'">
        <span> MEANS TO EXERCISE THE RIGHTS OF ACCESS, RECTIFICATION, CANCELLATION OR
        OPPOSITION (ARCO RIGHTS). </span> You can send an email to contact@eitur.mx, at any
        moment, to exercise your Rights of Access, Rectification, Cancellation or Opposition ("Rights
        BOW"). To exercise ARCO Rights, you (or your legal representative) must submit the request,
        identifying with the following documentation:
    </p>

    <p *ngIf = "idioma == 'en'"> I. Name of the user or owner. </p>

    <p *ngIf = "idioma == 'en'"> II. Address of the user or owner or other means to communicate the response to your request. </p>

    <p *ngIf = "idioma == 'en'"> III. Documents that prove your identity (IFE / INE or passport) and, where appropriate, the documents that
        certify the legal representation of the applicant.
    </p>

    <p *ngIf = "idioma == 'en'"> IV. A description of the information / data on which you are trying to exercise your rights
        ARCO and the rights you would like to exercise.
    </p>

    <p *ngIf = "idioma == 'en'">
        If one or more of the documents mentioned above are not included, and / or the documents
        have errors or omissions, the Company will notify you within 3 business days of receipt
        of the application and will ask for the missing documents and / or the pertinent corrections; will take 5 business days
        from that notification to provide the updated information, otherwise, the request will be
        understand as not presented.
    </p>

    <p *ngIf = "idioma == 'en'">
        <span> TRANSFER OF PERSONAL DATA. </span> The Company may disclose your personal data to
        those third parties that, by virtue of the services and products offered, need to know them to comply
        fully with them.
    </p>


    <p *ngIf = "idioma == 'en'">
        Likewise, the Company may disclose your information to the competent authorities in terms of the
        applicable legislation; Any transfer of your personal data without consent will be made
        in accordance with Article 37 of the LFPDPPP.
    </p>

    <p *ngIf = "idioma == 'en'">
        <span> WEB BEACONS. </span> The Company may or may not use tracking technologies such as web beacons,
        similar to cookies, to collect data about your visits to the Portal; these are small images
        embedded in web content or email messages, which are not
        are normally visible to Users and that allow us to generate content almost
        personalized to offer you a better experience when you use our Portal.
    </p>

    <p *ngIf = "idioma == 'en'">
        In case of not agreeing with any of the conditions established here, the User always
        You can change your browser settings.
    </p>

    <p *ngIf = "idioma == 'en'">
        <span> CHANGES TO THE PRIVACY NOTICE. </span> This privacy notice may suffer
        modifications, changes or updates derived, among other things, by new requirements
        legal; The Company's own needs, due to the products or services we offer; for our
        privacy practices; due to changes in our business model, or for other reasons
    </p>

    <p *ngIf = "idioma == 'en'">
        Any modification to this privacy notice will be notified to you through any of the
        following means: a written communication sent to your home address; by the email you indicate;
        a message to your mobile phone; a message made known through the Portal or by any means
        electronic that you use to carry out operations; or, in newspapers of wide circulation the address
        social of the Company.
    </p>

</div>
