<div class="container">
    <div class="row content-center">

        <div class="col-12 col-md-4 row content-center">
            <div class="col-2">
                <img src="assets/img/LOCATION-ICON.png" alt="">
            </div>
            <div class="col-10 content-center">
                <p>Reg. 228, Mza. 58, Lote 9, C:P. 77516 Cancún, Quintana Roo</p>
            </div>
        </div>

        <div class="col-12 col-md-4 row content-center">
            <div class="col-2">
                <img src="assets/img/PHONE-ICON.png" alt="">
            </div>
            <div class="col-10">
                <p>MX: +52 998 310 2861 <br> MX: +52 998 561 8934 <br> USA: +1 435 375 8519 </p>
            </div>
        </div>

        <div class="col-12 col-md-4 row content-center">
            <div class="col-2">
                <img src="assets/img/MAIL-ICON.png" alt="">
            </div>
            <div class="col-10 content-center">
                <p>contact@eitur.mx</p>
            </div>
        </div>

    </div>

</div>
