<header>
    <app-navbar></app-navbar>
</header>
<main>
    <router-outlet></router-outlet>
    <app-loading-page *ngIf="localService.isLoading" ></app-loading-page>
    <app-video></app-video>
    <app-copyright></app-copyright>
    <app-redes-sociales></app-redes-sociales>
    <app-modal-reserva></app-modal-reserva>
    <app-chatboot></app-chatboot>
</main>
