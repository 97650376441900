import { Component, HostListener, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';


const idioma = environment.idioma;


@Component({
  selector: 'app-reservacion-gral-movil',
  templateUrl: './reservacion-gral-movil.component.html',
  styleUrls: ['./reservacion-gral-movil.component.css']
})
export class ReservacionGralMovilComponent implements OnInit {

  @Input() public scrollMinimo: number = 0;

  public claseEntrada: string;
  public txtMostrar: string = '¡ RESERVAR AHORA !';
  public mostrarBtn: boolean = false;

  constructor() { 
    this.claseEntrada = '';

  }

  ngOnInit(): void {

    if( idioma === 'en' ) {
      this.txtMostrar = '¡ BOOK NOW !';
    }

  }

  @HostListener("window:scroll", []) onWindowScroll() {
    const scrollVertical = window.pageYOffset 
          || document.documentElement.scrollTop 
          || document.body.scrollTop || 0;
    if( scrollVertical >= this.scrollMinimo ) {
      this.mostrarBtn = true;
      setTimeout(() => {
        this.claseEntrada = 'entra';
      }, 50);
    } else {
      this.claseEntrada = '';
        this.mostrarBtn = false;
    }
}

}
