<div class="container" >

    <div *ngIf="correctoMensaje" class="alert alert-success alert-dismissible fade show" role="alert" style="font-family: Champagne;font-size: 13px;">
        {{  txtFormContact.CORRECTO  }}
      </div>

      <div *ngIf="errorMensaje" class="alert alert-success alert-dismissible fade show" role="alert" style="font-family: Champagne;font-size: 12px;">
        {{ txtFormContact.ERROR }}
      </div>

      <p *ngIf="enviandoMensaje" style="font-size: 15px;margin:0;font-family: Champagne;color: rgb(48, 143, 206);">{{ txtFormContact.ENVIANDO }}</p>
      <div class="progress" *ngIf="enviandoMensaje" style="margin-bottom: 5px;">
        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
      </div>


    <div class="row">
        <div class="col-12 col-md-12" >
            <input [(ngModel)]="_mcorreo_.Nombre" type="text" class="form-control" [placeholder]="txtFormContact.IPT1">
        </div>
        <div class="col-12 col-md-6" >
            <input [(ngModel)]="_mcorreo_.Correo" type="text" class="form-control" [placeholder]="txtFormContact.IPT2">
        </div>
        <div class="col-12 col-md-6" >
            <input [(ngModel)]="_mcorreo_.Telefono" type="number" class="form-control" [placeholder]="txtFormContact.IPT3">
        </div>
        <div class="col-12 col-md-12" >
            <textarea [(ngModel)]="_mcorreo_.Mensaje" class="form-control" rows="5" [placeholder]="txtFormContact.IPT4"></textarea>
        </div>
        <div style="text-align: center;" >
            <button class="btn" (click)="sendCorreo()" [disabled]="_mcorreo_.Nombre=='' || _mcorreo_.Correo=='' || _mcorreo_.Telefono=='' || _mcorreo_.Mensaje==''"  > {{ txtFormContact.BTN }} </button>
        </div>
    </div>

    <div style="width: 100%; margin-left: auto !important; margin-right: auto !important;margin-top: 3em;" >
      <img src="assets/img/logo-sectur-eitur-footer.png" style="width: 100%;">
    </div>

</div>

<br>
<br>
<app-footer-secondary-foot ></app-footer-secondary-foot>
<br>

<div class="row txt-footer-menu">
    <div class="col-md-1"></div>
    <div class="col-md-2 txt-footmenu" [routerLink]="['/home']" routerLinkActive="router-link-active" > {{ txtFormContact.OP1 }} </div>
    <div class="col-md-2 txt-footmenu" [routerLink]="['/tours']" routerLinkActive="router-link-active" > {{ txtFormContact.OP2 }} </div>
    <div class="col-md-2 txt-footmenu" [routerLink]="['/package']" routerLinkActive="router-link-active" > {{ txtFormContact.OP3 }} </div>
    <div class="col-md-2 txt-footmenu" [routerLink]="['/about']" routerLinkActive="router-link-active" > {{ txtFormContact.OP4 }} </div>
    <div class="col-md-2 txt-footmenu" [routerLink]="['/contact']" routerLinkActive="router-link-active" > {{ txtFormContact.OP5 }} </div>
    <div class="col-md-1"></div>
</div>
