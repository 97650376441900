import { LocalDataServiceService } from 'src/app/services/local-data-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sin-datos-reservacion',
  templateUrl: './sin-datos-reservacion.component.html',
  styleUrls: ['./sin-datos-reservacion.component.css']
})
export class SinDatosReservacionComponent implements OnInit {

  idioma: string;

  constructor( private localData: LocalDataServiceService ) {
     this.idioma = this.localData.idiomaActual;
  }

  ngOnInit(): void {
  }

  regresar() {
    location.reload();      
  }

}
