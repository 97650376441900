import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-facilidades-home',
  templateUrl: './facilidades-home.component.html',
  styleUrls: ['./facilidades-home.component.css']
})
export class FacilidadesHomeComponent implements OnInit {

  @Input() dataFacilidades: any;

  constructor() { 
  }

  ngOnInit(): void {
  }

}
