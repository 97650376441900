import { Router } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-tours-home',
  templateUrl: './tours-home.component.html',
  styleUrls: ['./tours-home.component.css']
})
export class ToursHomeComponent implements OnInit {

  @Input() title: string = '';
  @Input() titleBtn: string = '';

  public dataTours: any;
  public apiUrl: any = this.externalData._apirest;
  public _idioma: string = environment.idioma || 'en';
  showPrice: any;
  constructor( private externalData: ExternalDataServiceService,
               private router: Router ) {
  }



  ngOnInit(): void {
    this.externalData.getToursHome().subscribe( (data: any) => {
      this.dataTours = data.tours;
    });
    this.showPrice = this.externalData._Configuracion_;
  }


  subirScroll() {
    window.scroll(0,5);
    this.router.navigate(['/tours']);
  }

  getImagen( path: string ): string {
    return  path == 'no-image' ? `assets/img/no-image.png` : `${this.apiUrl}/${path}`;
  }

}
