<div class="container-xl">
    <div style="width: 85%; margin-left: auto !important; margin-right: auto !important; margin-top: 2em;margin-bottom: 2em;" >
      <img src="assets/img/logo-sectur-eitur-footer.png" style="width: 100%;">
    </div>
    <div class="row" style="width: 85%;margin-left: auto;margin-right: auto;">
      <div class="col-md-4" >
          <p style="font-size:15px;font-family: Champagne;"> {{ txtFooter.DESC }} </p>
          <br>
          <div class="row" style="font-family:Montserrat; font-size:15px">
            <div class="col-md-2" >
              <img src="assets/img/LOCATION-ICON.png" alt="">
            </div>
            <div class="col-md-10" style="font-family: Champagne;font-size: 15px;"> Reg. 228, Mza. 58, Lote 9, C:P. 77516 <br>Cancún, Quintana Roo</div>
          </div>
          <div class="row" style="font-family:Montserrat; font-size:15px;margin-top:15px">
            <div class="col-md-2" >
              <img src="assets/img/PHONE-ICON.png" alt="">
            </div>
            <div class="col-md-10" style="font-family: Champagne;font-size: 15px;"> MX: +52 998 310 2861 <br> MX: +52 998 561 8934 <br> USA: +1 435 375 8519 </div>
          </div>
          <div class="row" style="font-family:Montserrat; font-size:15px;margin-top: 15px;">
            <div class="col-md-2" >
              <img src="assets/img/MAIL-ICON.png" alt="">
            </div>
            <div class="col-md-10" style="font-family: Champagne;font-size: 15px;" > contact@eitur.mx</div>
          </div>
      </div>

      <div class="col-md-4" >
        <h3 style="font-size: 22px;color: #23a9e1;font-weight: bold;margin-bottom: 21px;font-family: Champagne;">{{ txtFooter.NAVE }}</h3>
        <p class="txt-footmenu" [routerLink]="['/home']" routerLinkActive="router-link-active" >{{ txtFooter.OP1 }}</p>
        <p class="txt-footmenu" [routerLink]="['/tours']" routerLinkActive="router-link-active" >{{ txtFooter.OP2 }}</p>
        <p class="txt-footmenu" [routerLink]="['/package']" routerLinkActive="router-link-active" >{{ txtFooter.OP3 }}</p>
        <p class="txt-footmenu" [routerLink]="['/about']" routerLinkActive="router-link-active" >{{ txtFooter.OP4 }}</p>
        <p class="txt-footmenu" [routerLink]="['/contact']" routerLinkActive="router-link-active" >{{ txtFooter.OP5 }}</p>
      </div>

      <div class="col-md-4" >
        <h3 style="font-size: 22px;color: #23a9e1;font-weight: 700;margin-bottom: 22px;font-family: Champagne;">{{ txtFooter.CONT }}</h3>

        <div *ngIf="correctoMensaje" class="alert alert-success alert-dismissible fade show" role="alert" style="font-family: Champagne;font-size: 13px;">
          {{  txtFooter.CORRECTO  }}
        </div>

        <div *ngIf="errorMensaje" class="alert alert-success alert-dismissible fade show" role="alert" style="font-family: Champagne;font-size: 12px;">
          {{ txtFooter.ERROR }}
        </div>

        <p *ngIf="enviandoMensaje" style="font-size: 15px;margin:0;font-family: Champagne;color: rgb(48, 143, 206);">{{ txtFooter.ENVIANDO }}</p>
        <div class="progress" *ngIf="enviandoMensaje" style="margin-bottom: 5px;">
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>
        </div>

        <input [(ngModel)]="_mcorreo_.Nombre" type="text" style="font-size:15px;margin-bottom: 15px; height: 39px;width: 100%;border-radius: 0;color: rgb(66, 66, 66); background: #f9d08d;border-color: #f9d08d;font-family: Champagne;" class="form-control" [placeholder]="txtFooter.IPT1">
        <input [(ngModel)]="_mcorreo_.Correo" type="text" style="font-size:15px;margin-bottom: 15px; height: 39px;width: 100%;border-radius: 0;color: rgb(66, 66, 66); background: #f9d08d;border-color: #f9d08d;font-family: Champagne;" class="form-control" [placeholder]="txtFooter.IPT2">
        <input [(ngModel)]="_mcorreo_.Telefono" type="number" style="font-size:15px;margin-bottom: 15px; height: 39px;width: 100%;border-radius: 0;color: rgb(66, 66, 66); background: #f9d08d;border-color: #f9d08d;font-family: Champagne;" class="form-control" [placeholder]="txtFooter.IPT3">
        <textarea [(ngModel)]="_mcorreo_.Mensaje" style="font-size:15px; margin-bottom: 15px;width: 100%;border-radius: 0;color: rgb(66, 66, 66); background: #f9d08d;border-color: #f9d08d;font-family: Champagne;" class="form-control" [placeholder]="txtFooter.IPT4" rows="4"></textarea>
        <button class="btn" (click)="sendCorreo()" [disabled]="_mcorreo_.Nombre=='' || _mcorreo_.Correo=='' || _mcorreo_.Telefono=='' || _mcorreo_.Mensaje==''" style="background: #f5a31f; height: 35px;width: 50%;border-color: transparent;color: #fff;float: right;font-family: Champagne;" >{{ txtFooter.BTN }}</button>
      </div>
    </div>
    <br>
  </div>
