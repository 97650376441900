import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-alerta-no-registro',
  templateUrl: './alerta-no-registro.component.html',
  styleUrls: ['./alerta-no-registro.component.css']
})
export class AlertaNoRegistroComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
