import { Component, OnInit, Input } from '@angular/core';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-comparacion-servicio',
  templateUrl: './comparacion-servicio.component.html',
  styleUrls: ['./comparacion-servicio.component.css']
})
export class ComparacionServicioComponent implements OnInit {

  public txtTabla: any;
  @Input() nameTour: string = '';

  constructor( private _local: LocalDataServiceService ) {

    this.txtTabla = [];
    this._local.getTablaComparacion().subscribe( (data: any) => {
      this.txtTabla = data;
    });

  }

  ngOnInit(): void {
  }




}
