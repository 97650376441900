<div class="bg-form-reserva" >
  <app-reserva-principal></app-reserva-principal>
</div>

<!---
  <app-popup></app-popup>
-->

<div>
    <app-carousel-banner (_ejecutarFuncion_)="ejecutarBtnCarrousel($event)" [idPagina]="1" ></app-carousel-banner>
</div>
<div class="tourResume" >
    <app-tours-home [title]="txtHome.MJDT" [titleBtn]="txtHome.MJDB" ></app-tours-home>
</div>

<div class="container facilidadHome">
    <div class="row">
        <div class="col-md-6" style="margin-bottom: 30px;">
            <img src="assets/img/home/CAMIONETA-FACILIDADES.png" style="width: 100%;">
        </div>
        <div class="col-md-6">
            <h2 style="font-size: 25px;color: #23a9e1;font-weight: 700;line-height: 30px;text-align: center;font-family: Champagne;"> {{ txtHome.FACT }} </h2>

                <app-facilidades-home [dataFacilidades]="dataFacilidad" ></app-facilidades-home>
        </div>
    </div>
</div>
<div style="margin-top: -8%; background-image: url('assets/img/home/CurvaDivision.png');border-color: #00659f; height: 13em;text-align: center;margin-bottom: -1px;"></div>

<div class="comentarios">
    <br><br>
    <h2 style="font-size: 25px;color: #fff;font-weight: 700;line-height: 30px;text-align: center;font-family: Champagne;"> NUESTROS CLIENTES Y PORQUE NOS RECOMIENDAN </h2>
    <br>
    <app-carousel-comentarios></app-carousel-comentarios>
    <br><br>
</div>
<app-footer></app-footer>
<app-reservacion-gral [scrollMinimo]="270" ></app-reservacion-gral>
<app-reservacion-gral-movil [scrollMinimo]="550" ></app-reservacion-gral-movil>
