<div class="container padding-arriba" >
    <h4>{{ txtForm.TIT }}</h4>
    <div class="row">
      
        <div class="col-12 col-md-2">
          <label for="inputEmail4" class="form-label">{{ txtForm.TRA }}</label>
          <select class="form-control" (change)="asignarTraslado($event)" >
          
            <option disabled="disabled"></option>
            <optgroup [label]="txtForm.GRUP1">
              <option style="font-size: 1em !important;" [value]="TRA1.DCLA" > {{ TRA1.DTRA }} </option>
              <option style="font-size: 1em !important;" [value]="TRA4.DCLA" > {{ TRA4.DTRA }} </option>
            </optgroup>
            <option disabled="disabled"></option>
            <optgroup [label]="txtForm.GRUP2">
              <option style="font-size: 1em !important;" [value]="TRA2.DCLA" > {{ TRA2.DTRA }} </option>
              <option style="font-size: 1em !important;" [value]="TRA3.DCLA" > {{ TRA3.DTRA }} </option>
              <option style="font-size: 1em !important;" [value]="TRA5.DCLA" > {{ TRA5.DTRA }} </option>
            </optgroup>
            <option disabled="disabled"></option>
          
          </select>
        </div>
        
        <div class="col-12 col-md-3">
          <label for="inputPassword4" class="form-label">{{ txtForm.DES }}</label>
          <app-input-search *ngIf="tipoDeTraslado == 'RTHH'" class="iptSearch" (pushData)="getDataDesde( $event )" (pushDataTxt)="cambiarValorDesdeTxt( $event )" ></app-input-search>
          <app-input-search *ngIf="tipoDeTraslado == 'OWHA'" class="iptSearch" (pushData)="getDataDesde( $event )" (pushDataTxt)="cambiarValorDesdeTxt( $event )" ></app-input-search>
          <app-input-search *ngIf="tipoDeTraslado == 'OWHH'" class="iptSearch" (pushData)="getDataDesde( $event )" (pushDataTxt)="cambiarValorDesdeTxt( $event )" ></app-input-search>
          <app-input-search-default *ngIf="tipoDeTraslado == 'RTAH' || tipoDeTraslado == 'OWAH'" ></app-input-search-default>
          <p class="campo-requerido" *ngIf="_formreserva_.Desde == 0 " >* {{ txtForm.REQ }} </p>
          <p class="campo-requerido" *ngIf="_formreserva_.Hasta! > 0 && this._formreserva_.Desde == this._formreserva_.Hasta" >* {{ txtForm.MISL }} </p>
        </div>

        <div class="col-12 col-md-3">
          <label for="inputAddress" class="form-label">{{ txtForm.HAS }}</label>
          <app-input-search *ngIf="tipoDeTraslado == 'RTAH'" class="iptSearch" (pushData)="getDataHasta( $event )" (pushDataTxt)="cambiarValorHastaTxt( $event )"></app-input-search>
          <app-input-search *ngIf="tipoDeTraslado == 'RTHH'" class="iptSearch" (pushData)="getDataHasta( $event )" (pushDataTxt)="cambiarValorHastaTxt( $event )"></app-input-search>
          <app-input-search *ngIf="tipoDeTraslado == 'OWAH'" class="iptSearch" (pushData)="getDataHasta( $event )" (pushDataTxt)="cambiarValorHastaTxt( $event )"></app-input-search>
          <app-input-search *ngIf="tipoDeTraslado == 'OWHH'" class="iptSearch" (pushData)="getDataHasta( $event )" (pushDataTxt)="cambiarValorHastaTxt( $event )"></app-input-search>
          <app-input-search-default *ngIf="tipoDeTraslado == 'OWHA'" ></app-input-search-default>
          <p class="campo-requerido" *ngIf="_formreserva_.Hasta == 0">* {{ txtForm.REQ }} </p>
          <p class="campo-requerido" *ngIf="_formreserva_.Hasta! > 0 && this._formreserva_.Desde == this._formreserva_.Hasta" >* {{ txtForm.MISL }} </p>
        </div>
        
        <div class="col-6 col-md-2" *ngIf="tipoDeTraslado == 'RTAH' || tipoDeTraslado == 'RTHH'">
          <label for="inputAddress2" class="form-label">{{ txtForm.LLE }}</label>
          <input [min]="_fechaMinimaLlegada" (change)="changeFechaSalida()" [(ngModel)]="_formreserva_.Llegada" type="date" class="form-control">
          <p class="campo-requerido" *ngIf="(tipoDeTraslado == 'RTAH' || tipoDeTraslado == 'RTHH') &&  _formreserva_.Llegada == ''">* {{ txtForm.REQ }} </p>
        </div>

        <div class="col-6 col-md-2" *ngIf="tipoDeTraslado == 'RTAH' || tipoDeTraslado == 'RTHH'">
          <label for="inputCity" class="form-label">{{ txtForm.SAL }}</label>
          <input [min]="_fechaMinimaSalida" type="date" (change)="validaFechaSalida( $event )" [(ngModel)]="_formreserva_.Salida" class="form-control">
          <p class="campo-requerido" *ngIf="(tipoDeTraslado == 'RTAH' || tipoDeTraslado == 'RTHH') &&  _formreserva_.Salida == ''">* {{ txtForm.REQ }} </p>
        </div>

        <div class="col-12 col-md-4" *ngIf="tipoDeTraslado == 'OWAH'" >
          <label for="inputCity" class="form-label">{{ txtForm.SERVI }}</label>
          <input [min]="_fechaMinimaLlegada" (change)="validaFechaLlegadaServ($event)" type="date" [(ngModel)]="_formreserva_.Llegada" class="form-control">
          <p class="campo-requerido" *ngIf="(tipoDeTraslado == 'OWAH') &&  _formreserva_.Llegada == ''">* {{ txtForm.REQ }} </p>
        </div>

        <div class="col-12 col-md-4" *ngIf="tipoDeTraslado == 'OWHA' || tipoDeTraslado == 'OWHH'" >
          <label for="inputCity" class="form-label">{{ txtForm.SERVI }}</label>
          <input [min]="_fechaMinimaLlegada" (change)="validaFechaSalidaServ($event)" type="date" [(ngModel)]="_formreserva_.Salida" class="form-control">
          <p class="campo-requerido" *ngIf="(tipoDeTraslado == 'OWHA' || tipoDeTraslado == 'OWHH') &&  _formreserva_.Salida == ''">* {{ txtForm.REQ }} </p>
        </div>

        <div class="col-md-3"></div>

        <div class="col-6 col-md-2" >
          <label for="inputState" class="form-label"> {{ txtForm.ADU }} </label>
          <select id="inputState" class="form-select" [(ngModel)]="_formreserva_.Adultos"  >
            <option [selected]="i==1" *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [value]="i"> {{i}} </option>
          </select>
        </div>

        <div class="col-6 col-md-2">
          <label for="inputZip" class="form-label"> {{ txtForm.NIN }} </label>
          <select id="inputState" class="form-select" [(ngModel)]="_formreserva_.Ninos">
            <option [selected]="i==0" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [value]="i"> {{i}} </option>
          </select>        
        </div>

        <div class="col-md-2" style="margin-top: 2em;">
          <button type="submit" data-bs-dismiss="modal" aria-label="Close" (click)="enviarFormulario()" class="btn " style="width: 100%;" [disabled]="!validarCampos()" >{{ txtForm.BUS }}</button>
        </div>

        <div class="col-md-3"></div>

      </div>

    <br>
        <p class="nota" ><span style="font-weight: bold;">{{ txtForm.NOT1 }}:</span> {{ txtForm.NOT2 }} <span style="font-weight: bold;cursor: pointer; text-decoration: underline; " [routerLink]="['/contact']" routerLinkActive="router-link-active"> {{ txtForm.NOT3 }} </span></p>
    <br>

</div>