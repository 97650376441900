<div class="btn-posicion-instagram">
    <a title="Instagram" href="https://www.instagram.com/eitur_cancun/" target="_blank" class="btn desing-btn" style="background-color: #23a9e1 !important;">
        <img src="assets/img/icon-redes/instagram.png" width="25" height="25" style="margin-top: 8%;">
    </a>
</div>

<div class="btn-posicion-facebook">
    <a title="Facebook" href="https://www.facebook.com/EiturTransportadoraTuristica/" target="_blank" class="btn desing-btn" style="background: #23a9e1 !important;">
        <img src="assets/img/icon-redes/face-book.png" width="23" height="23" style="margin-top: 8%;">
    </a>
</div>

<div class="btn-posicion-tripadvisor">
  <a title="TripAdvisor" href="https://www.tripadvisor.com.mx/Attraction_Review-g150807-d24971891-Reviews-EITUR_MX-Cancun_Yucatan_Peninsula.html" target="_blank" class="btn desing-btn" style="background: #23a9e1 !important;">
      <img src="assets/img/icon-redes/trip.png" width="23" height="23" style="margin-top: 8%;">
  </a>
</div>
