<app-sin-datos-reservacion class="contenido" *ngIf="!sePuedeHacerLaReserva" ></app-sin-datos-reservacion>

<div class="container-xl contenido" *ngIf="sePuedeHacerLaReserva"  style="height: auto; width: 100%;">
    <div class="row" >

      <h5 style="font-size: 15px;margin: 0px;font-weight: bold;margin-bottom: 10px;margin-top: 15px;" >{{ allTxtDetalle.IMPORTANT }}</h5>

        <div class="col-md-4">

            <h5 style="font-size: 20px;margin: 0px;font-weight: bold;margin-bottom: 18px;" > {{ allTxtDetalle.TRESER }} :</h5>

            <div class="bg-reserva">
                <div style="text-align: center; background: rgba(255, 255, 255, 0.1) ; border-radius: 5px;margin-bottom: 15px;">
                    <img src="assets/img/home/CAMIONETA-FACILIDADES-old.png" style="width: 50%;margin-bottom: 10px;margin-top: 15px;">
                </div>
                <h5 style="font-size: 13px;color:#ffffff;" > {{ allTxtDetalle.TRASL }}: <span style="color: #d3ccccec;"> {{ _reservacion_.Traslado_Txt }} </span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">
                <h5 style="font-size: 13px;color:#ffffff;"> {{ allTxtDetalle.DESDE }}:
                    <span style="color: #d3ccccec;" > {{ _reservacion_.Desde_Txt }} </span>
                </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">
                <h5 style="font-size: 13px;color:#ffffff;"> {{ allTxtDetalle.HASTA }}:
                    <span style="color: #d3ccccec;" > {{ _reservacion_.Hasta_Txt }} </span>
                </h5>
                <hr style="margin-top:5px;margin-bottom:5px;" *ngIf="_reservacion_.Llegada" >
                <h5 style="font-size: 13px;color:#ffffff;"  *ngIf="_reservacion_.Llegada" > {{ allTxtDetalle.LLEGADA }}: <span style="color: #d3ccccec;">{{ _reservacion_.Llegada }}</span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;" *ngIf="_reservacion_.Salida" >
                <h5 style="font-size: 13px;color:#ffffff;"  *ngIf="_reservacion_.Salida && this._pre_reservacion.Traslado == 'RT'" > {{ allTxtDetalle.SALIDA }}: <span style="color: #d3ccccec;"> {{ _reservacion_.Salida }} </span> </h5>
                <h5 style="font-size: 13px;color:#ffffff;"  *ngIf="_reservacion_.Salida && this._pre_reservacion.Traslado == 'OW'" > {{ allTxtDetalle.SERVICIO }}: <span style="color: #d3ccccec;"> {{ _reservacion_.Salida }} </span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">
                <h5 style="font-size: 13px;color:#ffffff;" > {{ allTxtDetalle.ADULTOS }}: <span style="color: #d3ccccec;"> {{ _reservacion_.Adultos }} </span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">
                <h5 style="font-size: 13px;color:#ffffff;" > {{ allTxtDetalle.NINOS }}: <span style="color: #d3ccccec;"> {{ _reservacion_.Ninos }} </span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">

                <div style="text-align: center;margin-bottom: -25px;" *ngIf="_reservacion_.Codigo_Descuento" >
                    <p style="font-weight: bold;font-size: 30px;color: #ec701d;text-decoration:line-through;">${{ _reservacion_.Costo }} <span style="font-size: 30px;" >{{ allTxtDetalle.MONEDA }}</span></p>
                </div>

                <div style="text-align: center;margin-bottom: -50px;" *ngIf="_reservacion_.Codigo_Descuento" >
                    <p style="font-weight: bold;font-size: 20px;color: rgb(23, 206, 23);"> -{{_reservacion_.Costo_Descuento}}%</p>
                </div>

                <div style="text-align: center;margin-top: 30px;">
                    <p style="font-weight: bold;font-size: 60px;color: #ffffff;">${{ _reservacion_.Costo_Total | number : '1.0-0' }} <span style="font-size: 40px;" >{{ allTxtDetalle.MONEDA }}</span></p>
                </div>

                <app-input-cupon-reserva
                [txtDESCODE]="allTxtDetalle.DESCODE"
                [txtIPTCODE]="allTxtDetalle.IPTCODE"
                [txtBTNCODE]="allTxtDetalle.BTNCODE"
                [txtCODERRO]="allTxtDetalle.CODERRO"
                [txtCOD1COR]="allTxtDetalle.COD1COR"
                [txtCOD2COR]="allTxtDetalle.COD2COR"
                [fechaReserva]=" _reservacion_.Llegada == '' || _reservacion_.Llegada == null ? _reservacion_.Salida! : _reservacion_.Llegada!"
                (datosCodigo)="aplicarCupon( $event )" >
                </app-input-cupon-reserva>

            </div>

            <div style="text-align: center;">
               <br>
                <!--- AQUI PENDIENTE -->
                <p *ngIf="!validarDatos()" style="color: yellowgreen;margin-top: 25px;font-size: 15px;font-weight: bold;"> * {{ allTxtDetalle.LLENACPS }} </p>
                <button (click)="procederPago()" [disabled]="!validarDatos()" *ngIf="!iniciarPago" type="button" class="btn btn-success" style="width: 100%;font-family: Champagne"> {{ allTxtDetalle.METPAGO }} <i class="material-icons" style="margin-top:-5px;margin-bottom:0 ">payments</i> </button>
                <button *ngIf="iniciarPago" (click)="procederPago()" type="button" class="btn btn-link" style="width: 100%;margin-bottom: 5px;font-size: 15px;"> {{ allTxtDetalle.EDITAR }} </button>
                <app-btn-paypal *ngIf="iniciarPago" [_getreserva_]="_reservacion_"  (_respReserva_)="guardarReservacion($event)"></app-btn-paypal>
                <button *ngIf="iniciarPago" (click)="guardarParaPagoLlegada()" type="button" class="btn btn-success" style="width: 100%;font-weight: bold;font-style: italic;"> {{ allTxtDetalle.PAGOLLEGADA }}</button>
            </div>

        </div><!-- FIN COL-MD-4 -->

        <div class="col-md-8" >
            <!-- FORMULARIO DEL PASAJERO-->
            <h5 style="font-size: 20px;margin: 0px;font-weight: bold;margin-bottom: 18px;" >{{ allTxtDetalle.TDPASAJERO }}:</h5>
            <hr style="margin-top:5px;margin-bottom:2px;">
            <h6 style="font-size: 12px;font-family: Champagne" >{{ allTxtDetalle.DESTPASAJE }}</h6>

            <div class="contDatos row" >

                <!-- Datos personales -->
                <div class="col-md-6" >
                    <label>{{ allTxtDetalle.NOMBRE }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="text" class="form-control" [placeholder]="allTxtDetalle.NOMBRE" [(ngModel)]="_reservacion_.Nombre_Pasajero">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Nombre_Pasajero === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-6" >
                    <label>{{ allTxtDetalle.APELLIDO }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="text" class="form-control" [placeholder]="allTxtDetalle.APELLIDO" [(ngModel)]="_reservacion_.Apellido_Pasajero">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Apellido_Pasajero === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>
                <!-- END Datos personales -->

                <!-- Seccion para aerolinea de llegada -->

                <div class="col-md-6 row" style="padding: 0;margin: 0;"  *ngIf="this._pre_reservacion.TrasladoInterno=='RTAH' || this._pre_reservacion.TrasladoInterno=='OWAH'">
                  <label>{{ allTxtDetalle.AEROLINEAL }}: </label>
                  <div [ngClass]="{'col-md-12': !viewOtherAirlineLLegada ,'col-md-4': viewOtherAirlineLLegada }">
                    <select class="form-control" [disabled]="iniciarPago" (change)="changeAirlineLlegada($event)" >
                      <option value=""> {{ allTxtDetalle.SELECTIONAR }} </option>
                      <option *ngFor="let airline of _airlines.sort()" [value]="airline"> {{ airline }} </option>
                      <option value="Otro">{{ allTxtDetalle.AEROLINEAOTRO }}</option>
                    </select>
                  </div>
                  <div class="col-md-8 ipt-other" [ngClass]="{'display-none': !viewOtherAirlineLLegada }" >
                    <input autocomplete="off" [(ngModel)]="_reservacion_.Aerolina_Llegada" [readonly]="iniciarPago" type="text" class="form-control" [placeholder]="allTxtDetalle.OTHERAIRLINE">
                  </div>
                  <p class="txt-incorrecto" *ngIf="_reservacion_.Aerolina_Llegada === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                  <p style="color: transparent;" *ngIf="_reservacion_.Aerolina_Llegada !== ''" > x </p>
                </div>

                <!--<div class="col-md-6"  *ngIf="this._pre_reservacion.TrasladoInterno=='RTAH' || this._pre_reservacion.TrasladoInterno=='OWAH'">
                    <label>{{ allTxtDetalle.AEROLINEAL }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="text" class="form-control" [placeholder]="allTxtDetalle.AEROLINEAL" [(ngModel)]="_reservacion_.Aerolina_Llegada">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Aerolina_Llegada === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>-->

                <div class="col-md-3" *ngIf="this._pre_reservacion.TrasladoInterno=='RTAH' || this._pre_reservacion.TrasladoInterno=='OWAH'">
                    <label>{{ allTxtDetalle.VUELOL }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="text" class="form-control" [placeholder]="allTxtDetalle.VUELOL" [(ngModel)]="_reservacion_.Codigo_Vuelo_LLegada">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Codigo_Vuelo_LLegada === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-3" *ngIf="this._pre_reservacion.TrasladoInterno=='RTAH' || this._pre_reservacion.TrasladoInterno=='OWAH'">
                    <label>{{ allTxtDetalle.HORAL }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="time" class="form-control" [placeholder]="allTxtDetalle.AEROLINEAL" [(ngModel)]="_reservacion_.Hora_Vuelo_LLegada">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Hora_Vuelo_LLegada === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>
                <!-- END Seccion para aerolinea de llegada -->




                <div class="col-md-6 row" style="padding: 0;margin: 0;"  *ngIf="this._pre_reservacion.TrasladoInterno=='RTAH' || this._pre_reservacion.TrasladoInterno=='OWHA' ">
                  <label>{{ allTxtDetalle.AEROLINEAS }}: </label>
                  <div [ngClass]="{'col-md-12': !viewOtherAirlineSalida ,'col-md-4': viewOtherAirlineSalida }">
                    <select class="form-control" [disabled]="iniciarPago" (change)="changeAirlineSalida($event)" >
                      <option value=""> {{ allTxtDetalle.SELECTIONAR }} </option>
                      <option *ngFor="let airline of _airlines.sort()" [value]="airline"> {{ airline }} </option>
                      <option value="Otro">{{ allTxtDetalle.AEROLINEAOTRO }}</option>
                    </select>
                  </div>
                  <div class="col-md-8 ipt-other" [ngClass]="{'display-none': !viewOtherAirlineSalida }" >
                    <input autocomplete="off" [(ngModel)]="_reservacion_.Aerolina_Salida" [readonly]="iniciarPago" type="text" class="form-control" [placeholder]="allTxtDetalle.OTHERAIRLINE">
                  </div>
                  <p class="txt-incorrecto" *ngIf="_reservacion_.Aerolina_Salida === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                  <p style="color: transparent;" *ngIf="_reservacion_.Aerolina_Salida !== ''" > x </p>
                </div>

                <!-- Seccion para aerolinea de salida -->
                <!--<div class="col-md-5" *ngIf="this._pre_reservacion.TrasladoInterno=='RTAH' || this._pre_reservacion.TrasladoInterno=='OWHA' ">
                    <label>{{ allTxtDetalle.AEROLINEAS }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="text" class="form-control" [placeholder]="allTxtDetalle.AEROLINEAS" [(ngModel)]="_reservacion_.Aerolina_Salida">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Aerolina_Salida === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>-->

                <div class="col-md-3" *ngIf="this._pre_reservacion.TrasladoInterno=='RTAH' || this._pre_reservacion.TrasladoInterno=='OWHA'">
                    <label>{{ allTxtDetalle.VUELOS }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="text" class="form-control" [placeholder]="allTxtDetalle.VUELOS" [(ngModel)]="_reservacion_.Codigo_Vuelo_Salida">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Codigo_Vuelo_Salida === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-3" *ngIf="this._pre_reservacion.TrasladoInterno=='RTAH' || this._pre_reservacion.TrasladoInterno=='OWHA'">
                    <label>{{ allTxtDetalle.HORAS }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="time" class="form-control" [placeholder]="allTxtDetalle.AEROLINEAS" [(ngModel)]="_reservacion_.Hora_Vuelo_Salida">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Hora_Vuelo_Salida === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>
                <!-- END Seccion para aerolinea de salida -->






                <!-- Seccion para horario de salida del hotel de recepcion -->
                <div class="col-md-6" *ngIf="this._pre_reservacion.TrasladoInterno=='RTHH' || this._pre_reservacion.TrasladoInterno=='OWHH' ">
                    <label>{{ allTxtDetalle.HOTELLLEG }}: </label>
                    <input autocomplete="off" [value]="this._pre_reservacion.Desde_Txt" [readonly]="true" type="text" class="form-control">
                </div>

                <div class="col-md-6" *ngIf="this._pre_reservacion.TrasladoInterno=='RTHH'">
                    <label>{{ allTxtDetalle.HOTELHRLLE }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="time" class="form-control" [placeholder]="allTxtDetalle.HOTELHRLLE" [(ngModel)]="_reservacion_.Hora_Vuelo_LLegada">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Hora_Vuelo_LLegada === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>
                <!-- END Seccion para horario de salida del hotel de recepcion -->


                <!-- Seccion para horario de salida del hotel de salida -->
                <div class="col-md-6" *ngIf="this._pre_reservacion.TrasladoInterno=='RTHH'">
                    <label>{{ allTxtDetalle.HOTELSAL }}: </label>
                    <input autocomplete="off" [value]="this._pre_reservacion.Hasta_Txt" [readonly]="true" type="text" class="form-control">
                </div>

                <div class="col-md-6" *ngIf="this._pre_reservacion.TrasladoInterno=='RTHH' || this._pre_reservacion.TrasladoInterno=='OWHH'">
                    <label>{{ allTxtDetalle.HOTELHRSAL }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="time" class="form-control" [placeholder]="allTxtDetalle.HOTELHRSAL" [(ngModel)]="_reservacion_.Hora_Vuelo_Salida">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Hora_Vuelo_Salida === ''" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>
                <!-- END Seccion para horario de salida del hotel de salida -->



                <!-- Seccion para pais y numero de habitacion -->
                <div class="col-md-6" >
                    <label>{{ allTxtDetalle.PAIS }}: </label>
                    <select [disabled]="iniciarPago" class="form-control" [(ngModel)]="_reservacion_.Pais_Pasajero">
                        <option value=""> {{ allTxtDetalle.SELECTPAIS }} </option>
                        <option [value]="allTxtDetalle.OPTMEX">{{allTxtDetalle.OPTMEX}}</option>
                        <option [value]="allTxtDetalle.OPTUSD">{{allTxtDetalle.OPTUSD}}</option>
                        <option [value]="allTxtDetalle.OPTCND">{{allTxtDetalle.OPTCND}}</option>
                        <option *ngFor="let p of paises" [value]="p" > {{ p }} </option>
                    </select>
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Pais_Pasajero === '' || _reservacion_.Pais_Pasajero === null" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-6" >
                    <label>{{ allTxtDetalle.CUARTO }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="number" class="form-control" [placeholder]="allTxtDetalle.CUARTO" [(ngModel)]="_reservacion_.Numero_Cuarto">
                    <p class="txt-incorrecto" *ngIf=" _reservacion_.Numero_Cuarto == null" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>
                <!-- END Seccion para pais y numero de habitacion -->



                <!-- Seccion para mas datos del pasajero -->
                <div class="col-md-6" >
                    <label>{{ allTxtDetalle.EMAIL }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="email" class="form-control" [placeholder]="allTxtDetalle.EMAIL" [(ngModel)]="_reservacion_.Email_Pasajero">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Email_Pasajero === '' || !emailCorrecto()" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-6" >
                    <label>{{ allTxtDetalle.TELEFONO }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="number" class="form-control" [placeholder]="allTxtDetalle.TELEFONO" [(ngModel)]="_reservacion_.Telefono_Pasajero">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Telefono_Pasajero === '' || _reservacion_.Telefono_Pasajero == null" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>
                <!-- END Seccion para mas datos del pasajero -->



                <!-- Comentarios del pasajero -->
                <div class="col-md-12" >
                    <label>{{ allTxtDetalle.COMENT }}: </label>
                    <textarea [readonly]="iniciarPago" rows="2" type="email" class="form-control" [placeholder]="allTxtDetalle.COMENT" [(ngModel)]="_reservacion_.Comentarios_Reserva"></textarea>
                </div>
                <!-- END Comentarios del pasajero -->


                <!-- VALIDACIONES SI VIENEN EN UNA MISMA TERMINAL O SI REQUIERE FACTURA-->
                <div class="form-check col-md-6" style="margin-top: 15px;padding-left: 10%;" *ngIf="this._pre_reservacion.TrasladoInterno==='RTAH' || this._pre_reservacion.TrasladoInterno==='OWAH'" >
                    <input autocomplete="off" [disabled]="iniciarPago" class="form-check-input"  type="radio" name="exampleRadios" id="exampleRadios1" (change)="validarLlegadaMismaTerminal($event)" value="NO" checked>
                    <label class="form-check-label" for="exampleRadios1">
                        {{ allTxtDetalle.MTERMINAL }}
                    </label>
                </div>
                <div class="form-check col-md-6" style="margin-top: 15px;padding-left: 10%;" *ngIf="this._pre_reservacion.TrasladoInterno==='RTAH' || this._pre_reservacion.TrasladoInterno==='OWAH'">
                    <input autocomplete="off" [disabled]="iniciarPago" class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" (change)="validarLlegadaMismaTerminal($event)" value="SI">
                    <label class="form-check-label" for="exampleRadios2">
                        {{ allTxtDetalle.DTERMINAL }}
                    </label>
                </div>

                <div class="col-md-12" style="margin-top: 15px;text-align: right;" >
                    <hr style="margin: 10px;" *ngIf="this._pre_reservacion.TrasladoInterno==='RTAH' || this._pre_reservacion.TrasladoInterno==='OWAH'">
                    <div class="form-check form-check-inline">
                        <input autocomplete="off" [disabled]="iniciarPago" class="form-check-input" type="checkbox" id="inlineCheckbox1" [checked]="requiereFactura==='SI'" (change)="validarSiRequiereFactura( $event )" [value]="requiereFactura" >
                        <label class="form-check-label" for="inlineCheckbox1" style="color: #00659f;">* {{ allTxtDetalle.RFACTURA }}</label>
                    </div>
                </div>
                <!--END VALIDACIONES SI VIENEN EN UNA MISMA TERMINAL O SI REQUIERE FACTURA-->


            </div>

            <!---- FORMULARIO PARA FACTURACION -->
            <br>
            <h5 style="font-size: 20px;margin: 0px;font-weight: bold;margin-bottom: 18px;" >{{ allTxtDetalle.TFACTURA }}:</h5>
            <hr style="margin-top:5px;margin-bottom:2px;">
            <h6 style="font-size: 12px;font-family: Champagne" >{{ allTxtDetalle.DFACTURA }}</h6>

            <div class="contDatos row" >

                <div class="col-md-6" >
                    <label>{{ allTxtDetalle.FIPTNOMB }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="text" [(ngModel)]="_reservacion_.Nombre_Factura" class="form-control" [placeholder]="allTxtDetalle.FIPTNOMB">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Nombre_Factura === '' && requiereFactura=='SI'" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-6" >
                    <label>{{ allTxtDetalle.FIPTDIRE }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="text" [(ngModel)]="_reservacion_.Direccion_Factura" class="form-control" [placeholder]="allTxtDetalle.FIPTDIRE">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Direccion_Factura === '' && requiereFactura=='SI'" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-4" >
                    <label>{{ allTxtDetalle.FIPTCORREO }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="text" [(ngModel)]="_reservacion_.Correo_Factura" class="form-control" [placeholder]="allTxtDetalle.FIPTCORREO">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Correo_Factura === '' && requiereFactura=='SI'" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-4" >
                    <label>{{ allTxtDetalle.FIPTRFC }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="text" [(ngModel)]="_reservacion_.Rfc_Factura" class="form-control" [placeholder]="allTxtDetalle.FIPTRFC">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Rfc_Factura === '' && requiereFactura=='SI'" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-4" >
                    <label>{{ allTxtDetalle.FIPTCFDI }}:</label>
                    <select [(ngModel)]="_reservacion_.Cfdi_Factura" [disabled]="iniciarPago  || requiereFactura=='NO'" class="form-control" >
                        <option value=""> {{ allTxtDetalle.SELECTIONAR }} </option>
                        <option [value]="allTxtDetalle.USO1"> {{ allTxtDetalle.USO1 }} </option>
                        <option [value]="allTxtDetalle.USO2"> {{ allTxtDetalle.USO2 }} </option>
                        <option [value]="allTxtDetalle.USO3"> {{ allTxtDetalle.USO3 }} </option>
                        <option [value]="allTxtDetalle.USO4"> {{ allTxtDetalle.USO4 }} </option>
                        <option [value]="allTxtDetalle.USO5"> {{ allTxtDetalle.USO5 }} </option>
                        <option [value]="allTxtDetalle.USO6"> {{ allTxtDetalle.USO6 }} </option>
                        <option [value]="allTxtDetalle.USO7"> {{ allTxtDetalle.USO7 }} </option>
                        <option [value]="allTxtDetalle.USO8"> {{ allTxtDetalle.USO8 }} </option>
                        <option [value]="allTxtDetalle.USO9"> {{ allTxtDetalle.USO9 }} </option>
                        <option [value]="allTxtDetalle.USO10"> {{ allTxtDetalle.USO10 }} </option>
                        <option [value]="allTxtDetalle.USO11"> {{ allTxtDetalle.USO11 }} </option>
                        <option [value]="allTxtDetalle.USO12"> {{ allTxtDetalle.USO12 }} </option>
                        <option [value]="allTxtDetalle.USO13"> {{ allTxtDetalle.USO13 }} </option>
                        <option [value]="allTxtDetalle.USO14"> {{ allTxtDetalle.USO14 }} </option>
                        <option [value]="allTxtDetalle.USO15"> {{ allTxtDetalle.USO15 }} </option>
                        <option [value]="allTxtDetalle.USO16"> {{ allTxtDetalle.USO16 }} </option>
                        <option [value]="allTxtDetalle.USO17"> {{ allTxtDetalle.USO17 }} </option>
                        <option [value]="allTxtDetalle.USO18"> {{ allTxtDetalle.USO18 }} </option>
                        <option [value]="allTxtDetalle.USO19"> {{ allTxtDetalle.USO19 }} </option>
                        <option [value]="allTxtDetalle.USO20"> {{ allTxtDetalle.USO20 }} </option>
                        <option [value]="allTxtDetalle.USO21"> {{ allTxtDetalle.USO21 }} </option>
                        <option [value]="allTxtDetalle.USO22"> {{ allTxtDetalle.USO22 }} </option>
                    </select>
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Cfdi_Factura === '' && requiereFactura=='SI'" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-4" style="margin-top: 15px;">
                    <label>{{ allTxtDetalle.FIPTCIUD }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="text" [(ngModel)]="_reservacion_.Ciudad_Factura" class="form-control" [placeholder]="allTxtDetalle.FIPTCIUD">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Ciudad_Factura === '' && requiereFactura=='SI'" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-5" style="margin-top: 15px;">
                    <label>{{ allTxtDetalle.FIPTESTA }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="text" [(ngModel)]="_reservacion_.Estado_Factura" class="form-control" [placeholder]="allTxtDetalle.FIPTESTA">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Estado_Factura === '' && requiereFactura=='SI'" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

                <div class="col-md-3" style="margin-top: 15px;">
                    <label>{{ allTxtDetalle.FIPTCODP }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="number" [(ngModel)]="_reservacion_.Codigo_Postal" class="form-control" [placeholder]="allTxtDetalle.FIPTCODP">
                    <p class="txt-incorrecto" *ngIf="(_reservacion_.Codigo_Postal === '' || _reservacion_.Codigo_Postal === null) && requiereFactura=='SI'" > * {{ allTxtDetalle.LLENAIPT }} </p>
                </div>

            </div>

        </div> <!--FIN COL-MD-8-->

    </div>
</div>
