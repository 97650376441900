
<div class="modal fade show" *ngIf="displayModal" id="exampleModal" style="display: block;" tabindex="-1" aria-labelledby="exampleModalLabel" aria-modal="true" role="dialog">
  <div class="modal-dialog modal-dialog-centered  modal-lg">
    <div class="modal-content">
      <div class="modal-header" style="background: #000;border-color: #000;">
        <button  *ngIf="displayImg" (click)="closeModal()" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        <img  *ngIf="displayImg" id="img-banner" src="assets/img/popup.jpeg" class="img-fluid">
        <div *ngIf="!displayImg" style="width:100%;height: 45vh;background: #000;"></div>
      </div>
    </div>
  </div>
</div>

