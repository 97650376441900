<div class="carouselTour">
    <app-carousel-banner (_ejecutarFuncion_)="ejecutarBtnCarrousel($event)" [idPagina]="2" ></app-carousel-banner>
</div>

<div class="tourResume" >
    <app-tours-tours [title]="txtTours.TOUT" [titleSub]="txtTours.TOUS" [titleBtn]="txtTours.TOUB" ></app-tours-tours>
</div>


<app-footer></app-footer>
<app-reservacion-gral [scrollMinimo]="0" ></app-reservacion-gral>
<app-reservacion-gral-movil [scrollMinimo]="200" ></app-reservacion-gral-movil>
