<div class="carouselContact">
    <app-carousel-banner [idPagina]="5" ></app-carousel-banner>
</div>

<div class="container-form-contacto" >
    <br>
    <p id="text-contacto">{{ txtContacto.CONTT }}</p>
    <br>
    <app-footer-secondary></app-footer-secondary>
</div>

<app-reservacion-gral [scrollMinimo]="0" ></app-reservacion-gral>
<app-reservacion-gral-movil [scrollMinimo]="200" ></app-reservacion-gral-movil>
