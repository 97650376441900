import { Component, OnInit } from '@angular/core';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.css']
})
export class CopyrightComponent implements OnInit {

  txtCopyright: any;

  constructor( private localData: LocalDataServiceService ) { 
    this.txtCopyright = [];
  }

  ngOnInit(): void {
    this.getTxtCopy();
  }

  getTxtCopy() {
    this.localData.getCopyright().subscribe( data => {
      this.txtCopyright = data;
    });
  }

  _subirScroll(){
    window.scroll({
      top: 0,
      behavior: "smooth"
    });
  }

  getYear(): string {
    const date = new Date();
    return date.getFullYear().toString();
  }
}
