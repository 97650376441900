<!--<div class="slider">
    <div class="cell" >
        UNO
    </div>

    <div class="cell" >
        UNO
    </div>

    <div class="cell" >
        UNO
    </div>
</div>-->

<div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-indicators">
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
      <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    </div>
    <div class="carousel-inner">
        
        <div *ngFor="let c of comentarios; let i = index " class="carousel-item" [ngClass]="{active: i == 0 }">
        
            <div style="margin-left: auto; margin-right: auto;text-align: center;width: 80%;" >
                <img *ngIf="c.Genero=='H'" src="assets/img/usuario.jpg" style="width: 120px;height: 120px;border-radius: 50%;">
                <img *ngIf="c.Genero=='M'" src="assets/img/usuariom.jpg" style="width: 120px;height: 120px;border-radius: 50%;">
                <br><br>
                <h5 style="font-family: Champagne;color: #f5a31f;"> {{ c.Autor }} </h5>
                <p style="font-family: Champagne;font-style: italic; color: #fff;font-size: 13px;" >"{{ c.Descripcion }}"</p>
                <p style="font-family: Champagne;color: #f5a31f;">{{ c.Fecha }}</p>
            </div>

        </div>

       
      
    </div>
    <br><br>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button>
    
  </div>