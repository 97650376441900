import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-input-search-default',
  templateUrl: './input-search-default.component.html',
  styleUrls: ['./input-search-default.component.css']
})
export class InputSearchDefaultComponent implements OnInit {

  datoPorDefault: string;
  habilitado: boolean;

  constructor() {
    this.datoPorDefault = 'CANCUN AIRPORT/AEROPUERTO';
    this.habilitado = true;
  }

  ngOnInit() {

  }

}
