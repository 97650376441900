import { Component, HostListener, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

const idioma = environment.idioma;

@Component({
  selector: 'app-reservacion-gral',
  templateUrl: './reservacion-gral.component.html',
  styleUrls: ['./reservacion-gral.component.css']
})
export class ReservacionGralComponent implements OnInit{

  @Input() public scrollMinimo: number = 0;

  public claseEntrada: string;
  public txtMostrar: string = '¡ RESERVAR AHORA !';
  public mostrarBtn: boolean = false;

  constructor() {
    this.claseEntrada = '';

  }

  ngOnInit(): void {

    if( idioma === 'en' ) {
      this.txtMostrar = '¡ BOOK NOW !';
    }

    if( scrollY>= this.scrollMinimo ) {
      this.mostrarBtn = true;
      setTimeout(() => {
        this.claseEntrada = 'entra';
      }, 50);
    } else {
      this.claseEntrada = '';
        this.mostrarBtn = false;
    }

  }

  @HostListener("window:scroll", []) onWindowScroll() {
    const scrollVertical = window.pageYOffset
          || document.documentElement.scrollTop
          || document.body.scrollTop || 0;
    if( scrollVertical >= this.scrollMinimo ) {
      this.mostrarBtn = true;
      setTimeout(() => {
        this.claseEntrada = 'entra';
      }, 50);
    } else {
      this.claseEntrada = '';
        this.mostrarBtn = false;
    }
  }

}
