
<div class="container-xl" style="margin-top: 8%;">

    <div class="card mb-12" >
        <div class="row g-0">
          <div class="col-md-6" style="text-align: center;margin-bottom: 1%;">
            <img src="../../../assets/img/reserva-no-data.png " style="width: 90%;text-align: center;">
          </div>
          <div class="col-md-6">
            <div class="card-body">
              
                <p class="info-mensaje" *ngIf="idioma==='en'" > Please Call us at +52 998 310 2861 or Write us at contact@eitur.mx to confirm availability for your request.. </p>
                <p class="info-mensaje" *ngIf="idioma==='es'" > Por favor, llámenos al 1-800-818-9821 o escríbanos a contact@eitur.mx para confirmar disponibilidad para su solicitud. </p>
                <div class="cont-btn">
                    <button class="btn btn-success" (click)="regresar()" *ngIf="idioma==='es'"> REGRESAR </button>
                    <button class="btn btn-success" (click)="regresar()" *ngIf="idioma==='en'"> RETURN </button>
                </div>
            </div>
          </div>
        </div>
    </div>

</div>

<app-footer></app-footer>