import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ExternalDataServiceService } from './services/external-data-service.service';
import { Configuracion } from './models/configuracion.model';

const keypaypal = environment.keyPaypal;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'webeiturmx';

  dato: string = 'nomas';


  constructor( private externalData: ExternalDataServiceService) {
    const urlPaypal: Element | null = document.querySelector("#urlPaypal");

    let urlMoneda = '';

    if( window.navigator.language === 'es-ES' ) {
      localStorage.setItem('idioma', 'es');
    } else {
      localStorage.setItem('idioma', 'en');
    }

    if( localStorage.getItem('idioma') === null || localStorage.getItem('idioma') === undefined || localStorage.getItem('idioma') === 'en' ) {
      urlMoneda = `https://www.paypal.com/sdk/js?client-id=${ keypaypal }&currency=USD&disable-funding=credit,card`;
    } else {
      urlMoneda = `https://www.paypal.com/sdk/js?client-id=${ keypaypal }&currency=MXN&disable-funding=credit,card`;
    }

    urlPaypal!.setAttribute('src', urlMoneda);
    this.getConfiguration();
  }

  getConfiguration() {
    this.externalData.getConfiguracion()
    .subscribe( (data: any) => {
      this.externalData._Configuracion_.Costo_Dos_Terminales = data.configuracion[0].Costo_Dos_Terminales;
      this.externalData._Configuracion_.Mostrar_Precio_Tours = data.configuracion[0].Mostrar_Precio_Tours;
      this.externalData._Configuracion_.Tc_Dolar = data.configuracion[0].Tc_Dolar;
    });
  }

}


