import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { respPagoPaypal } from './../../../models/reservacion.model';
import { generarReserva } from 'src/app/models/reservacion.model';
import { LocalDataServiceService } from '../../../services/local-data-service.service';
declare const paypal: any;

@Component({
  selector: 'app-btn-paypal',
  templateUrl: './btn-paypal.component.html',
  styleUrls: ['./btn-paypal.component.css']
})
export class BtnPaypalComponent implements OnInit {

  @ViewChild( 'paypal', { static: true } ) paypalElement!: ElementRef;
  @Input() _getreserva_!: generarReserva;
  @Output() _respReserva_ = new EventEmitter<respPagoPaypal>();

  constructor( private localData: LocalDataServiceService ) { }

  ngOnInit(): void {

    paypal.Buttons({
      style: {
        color: 'blue'
      },
      createOrder:( data: any, actions: any) => {

        return actions.order.create({
          purchase_units: [
            {
              description: `RESERVA DE TRASPORTE: VIAJE ${this._getreserva_.Traslado_Txt }; ${this._getreserva_.Desde_Txt }; ${this._getreserva_.Hasta_Txt }`,
              amount: {
                currency_code:  this._getreserva_.Tipo_Moneda,
                value: this._getreserva_.Costo_Total
              }
            }
          ]
        })


      }, onApprove: async ( data: any, actions: any) => {

        this.localData.isLoading = true;

        const order = await actions.order.capture();

        if( order === null || order === undefined ) {
          return;
        }

        this._respReserva_.emit ({

          ok: true,
          respuesta: 'Reservación generada correctamente...',
          idOrden: order.id,
          fechaOrden: order.create_time,
          statusOrden: order.status,
          emailPayer: order.payer.email_address,
          ciudadCodePayer: order.payer.address.country_code,
          nombrePayer: order.purchase_units[0].shipping.name.full_name,
          idMerchant: order.purchase_units[0].payee.merchant_id,
          statusCaptura: order.purchase_units[0].payments.captures[0].status,
          idCaptura: order.purchase_units[0].payments.captures[0].id

        });

      }, onError: (err: any) => {
        console.log( err)
        this._respReserva_.emit( { ok: false, respuesta : 'No se pudo concretar el pago. Intentelo más tarde...' } );
      }
    }

    ).render( this.paypalElement.nativeElement ) ;

  }

}
