<div class="carouselBaner">
    <app-carousel-banner [idPagina]="4" ></app-carousel-banner>
</div>

<div style="height: auto;background: #00659f; padding: 15px;">
    <br>
    <h2 style="font-size: 25px;color: #fff;font-weight: 700;line-height: 30px;text-align: center;"> {{ aboutUs.QST }} </h2>
    <br>

    <div class="container-xl">
      <div class="row" style="width: 75%;margin-left: auto;margin-right: auto;">

        <div class="col-xs-6 col-md-6" style="color: #fff;">
          <p class="txt-primary" style="margin: 0;font-size: 18px;color: #f5a31f;font-weight: bold;">{{ aboutUs.DE1T }}</p><br>
          <p class="txt-secondary" style="margin: 0;font-size: 15px;text-align: justify;"> {{ aboutUs.DE1D }} </p>
          <br>
        </div>
        <div class="col-xs-6 col-md-6" style="margin-top: 5px">
          <img src="assets/img/quienes/CAMIONETA-QUIENES-SOMOS.png" style="width:100%;">
        </div>
        <hr>
      </div>


      <div class="row" style="width: 75%;margin-left: auto;margin-right: auto;">

        <div class="col-xs-6 col-md-6" style="margin-top: 5px">
          <img src="assets/img/quienes/FOTO_01_QUIENESSOMOS.png" style="width:100%;">
        </div>
        <div class="col-xs-6 col-md-6" style="color: #fff;">
          <p class="txt-primary" style="margin: 0;font-size: 18px;color: #f5a31f;font-weight: bold;">{{ aboutUs.DE2T }}</p><br>
          <p class="txt-secondary" style="margin: 0;font-size: 15px;text-align: justify;">
            {{ aboutUs.DE2D }}
            <br><br>
            {{ aboutUs.DE22D }}
          </p>
          <br>
          <br>
        </div>
        <hr>
      </div>
      <br>
      <div class="row" style="width: 75%;margin-left: auto;margin-right: auto;">

        <div class="col-xs-6 col-md-6" style="color: #fff;">
          <p class="txt-primary" style="margin: 0;font-size: 18px;color: #f5a31f;font-weight: bold;">{{ aboutUs.DE3T }}</p><br>
          <p class="txt-secondary" style="margin: 0;font-size: 15px;text-align: justify;">
            {{ aboutUs.DE3D }}
            <br><br>
            {{ aboutUs.DE33D }}
            </p>
          <br>
        </div>
        <div class="col-xs-6 col-md-6" style="margin-top: 5px">
          <img src="assets/img/quienes/FOTO_02_QUIENESSOMOS.png" style="width:100%;">
        </div>

      </div>



    </div>
    <br><br>
</div>

<app-footer></app-footer>
<app-reservacion-gral [scrollMinimo]="0" ></app-reservacion-gral>
<app-reservacion-gral-movil [scrollMinimo]="200" ></app-reservacion-gral-movil>
