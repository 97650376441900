import { Component, OnInit } from '@angular/core';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  datosMenu: any;
  _idioma: string;

  _abrirMenu: boolean = false;


  constructor( private localData: LocalDataServiceService  ) {
    this.datosMenu = [];
    this._idioma = localData.idiomaActual;
  }

  ngOnInit(): void {
    this.localData.getTxtMenu().subscribe( (data: any) => {
      this.datosMenu = data;
    });

  }

  changeIdioma( _idioma: string ) {

    if( localStorage.getItem('idioma') !== undefined && localStorage.getItem('idioma') === _idioma) {
      return;
    }

    localStorage.setItem( 'idioma', _idioma );
    location.reload();
  }

  abrirMenu() {
    if(this._abrirMenu) {
      this._abrirMenu = false;
    } else  if( !this._abrirMenu ) {
      this._abrirMenu = true;
    }
  }

}
