
<div class="carouselPackage">
    <app-carousel-banner (_ejecutarFuncion_)="ejecutarBtnCarrousel($event)"  [idPagina]="3" ></app-carousel-banner>
</div>

<div style="height: auto;background: #00659f; padding: 15px;">
    <h2 style="font-size: 25px;color: #fff;font-weight: 700;line-height: 30px;text-align: center;font-family: Champagne;"> {{ dataTxtPaquetes.PAQT }} </h2>
    <h2 style="font-size: 17px;color: #fff;text-align: center;font-family: Champagne;">{{ dataTxtPaquetes.PAQS }}</h2>
    <br>

    <div class="container-xl" *ngIf="dataPaquetes">
      <div class="row" style="width: 75%;margin-left: auto;margin-right: auto;" *ngFor="let p of dataPaquetes" >
        <div class="col-xs-6 col-md-6" style="color: #fff;">
          <h4 style="margin: 0;font-weight: 800;font-family: Champagne;"> {{ p.Nombre }} </h4>
          <p class="txt-subdescripcion" > PRIVATE VAN SERVICE </p>
          <p class="txt-subdescripcion" > ROUND TRIP </p>

          <app-txt-descrip-package [idPaquete]="p.Id" ></app-txt-descrip-package>
          <p class="txt-price" *ngIf="showPrice.Mostrar_Precio_Tours=='SI'" > ${{p.Costo | number : '1.2-2'}} {{ moneda }} </p>

        </div>
        <div class="col-xs-6 col-md-6" style="background: #fff; padding:5px;margin-top: 5px;border-radius: 5px;">
          <img [src]="getImagen(p.Img)" style="width:100%;height: 220px;">
          <button [routerLink]="['/destination-package-info',p.Id]"  class="btn" style="background-color: rgb(143, 142, 142); color: #fff; font-weight: bold;font-size: 10px;float: left;margin-right: 5%;margin-top: 5px;" > {{ dataTxtPaquetes.PAQB }} </button>
        </div>
        <hr style="margin-top: 10px;">
      </div>

    </div>
    <br><br>
</div>

<app-footer></app-footer>
<app-reservacion-gral [scrollMinimo]="0" ></app-reservacion-gral>
<app-reservacion-gral-movil [scrollMinimo]="200" ></app-reservacion-gral-movil>
