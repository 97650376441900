import { LocalDataServiceService } from 'src/app/services/local-data-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public txtHome: any;
  public dataFacilidad: any;

  constructor( private localData: LocalDataServiceService,
               private router: Router ) {

    this.txtHome = [];
    this.localData.getTxtHome().subscribe( (data: any) => {
      this.txtHome = data;
      this.dataFacilidad = data.FACILIDADES;
    });
  }

  ngOnInit(): void {
    this.localData.isLoading = true;
    this.subirScroll();
  }

  ejecutarBtnCarrousel( event: any ) {
    this.router.navigate(['/tours']);
  }

  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 3000);
  }

}
