import { Component, OnInit } from '@angular/core';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  public aboutUs: any;

  constructor( private localData: LocalDataServiceService ) {
    this.aboutUs = [];
    this.subirScroll();
}

  ngOnInit(): void {
    this.localData.isLoading = true;
    this.localData.getDataAbout().subscribe( data => {
      this.aboutUs = data;
    });
  }

  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 2000);
  }



}
