<div class="sticky-content fixed-top">
  <div style="background: #00659f; color:#fff; font-size:15px" >
    <div class="container">
      <div class="row" >
        <div class="col-12 col-md-5 txt-top-navbar" style="font-family: champagne;padding-right: 0 !important;" > MX: +52 998 310 2861 | +52 998 561 8934 </div>
        <div class="col-6  col-md-3 txt-top-navbar" style="font-family: champagne;padding-left: 0 !important;" >USA: +1 435 375 8519</div>
        <div class="col-6  col-md-4 flag-top-navbar" >
          <a href="#" *ngIf="_idioma==='en'" style="cursor: pointer;color:#fff" (click)="changeIdioma('es')">
            <span style="padding-right: 5px;font-family: champagne;" >Idioma: </span>
            <img class="img-idioma" src="assets/img/logo-mexico.png" style="width: 30px;height: 18px">
          </a>
          <a href="#" *ngIf="_idioma==='es'" style="cursor: pointer;color:#fff" (click)="changeIdioma('en')">
            <span style="padding-right: 5px;font-family: champagne;" >Language: </span>
            <img class="img-idioma" src="assets/img/logo-usa.png" style="width: 30px;height: 18px">
          </a>
        </div>
      </div>
    </div>
  </div>
  <nav class="navbar navbar-expand-lg navbar-light">

    <div class="container">
      <img style="cursor: pointer;outline: none;width: 10.5em;" routerLink="/home"  src="assets/img/logo-eitur.png">

      <button (click)="abrirMenu()" style="background: #156ba3; color: #fff !important;font-size: 15px;" class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
        <span class="material-icons" style="color: #fff;">menu</span>
      </button>


      <div class="collapse navbar-collapse" [ngClass]="{show:_abrirMenu}" id="navbarCollapse" style="margin-left: 1em;">

        <hr style="margin-top: 0;margin-bottom: 7px;border-color: transparent;color: transparent;" >
        <div style="text-align: center;" >
          <img src="assets/img/logo-safetravels.png" style="width: 2.4em;">
          <img src="assets/img/home/TRIPADVISOR.png" style="width: 2.4em;">
          <img src="assets/img/home/CERTIFICACION-YUCATAN.png" style="width: 2.4em;margin-right: 1em;">
        </div>

      <ul class="navbar-nav me-auto mb-3 mb-md-0" style="margin-left: auto;" >
          <li class="nav-item">
            <a (click)="abrirMenu()" class="nav-link font-title"  [routerLink]="['/home']" routerLinkActive="router-link-active"  > {{ datosMenu.OP1 }} </a>
          </li>

          <li class="nav-item">
            <a (click)="abrirMenu()" class="nav-link font-title"   [routerLink]="['/tours']" routerLinkActive="router-link-active"  > {{ datosMenu.OP2 }} </a>
          </li>

          <li class="nav-item">
            <a (click)="abrirMenu()" class="nav-link font-title"   [routerLink]="['/package']" routerLinkActive="router-link-active"  > {{ datosMenu.OP3 }} </a>
          </li>

          <li class="nav-item">
            <a (click)="abrirMenu()" class="nav-link font-title"   [routerLink]="['/about']" routerLinkActive="router-link-active"  > {{ datosMenu.OP4 }} </a>
          </li>

          <li class="nav-item">
            <a (click)="abrirMenu()" class="nav-link font-title"   [routerLink]="['/contact']" routerLinkActive="router-link-active"  > {{ datosMenu.OP5 }} </a>
          </li>

        </ul>


        <div style="width: 100px !important;padding: 0 !important;display: flex; justify-content: center; align-items: center;">
          <p class="font-title" (click)="abrirMenu()"  [routerLink]="['/my-reservation']" style="margin: 0 !important;text-align: center; width: 110px; background:#23a9e1 ; cursor: pointer;padding: 5px;border-radius: 5px;font-size: 0.7em;color: #fff !important;font-family: Champagne;"> {{ datosMenu.MYRES }} </p>
        </div>


      </div>


    </div>



</nav>
</div>


