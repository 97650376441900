import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Reservacion } from 'src/app/models/reservacion.model';
import { ReservacionTour } from './../models/reservacion.model';


const idioma = environment.idioma;

@Injectable({
  providedIn: 'root'
})
export class LocalDataServiceService {

  prospectoReserva!: Reservacion;
  prospectoTourDestino!: ReservacionTour;
  idiomaActual: string = idioma || 'en';
  isLoading: boolean = true;
  _clearIpt: string = '';

  constructor( private http: HttpClient ) {
  }

  getTxtMenu(){
    return this.http.get(`assets/datos-${idioma}/menu.json`);
  }

  getTxtFormReserva(){
    return this.http.get(`assets/datos-${idioma}/form-reserva.json`);
  }

  getTxtHome(){
    return this.http.get(`assets/datos-${idioma}/home.json`);
  }

  getFooterReserva(){
    return this.http.get(`assets/datos-${idioma}/footer-principal.json`);
  }

  getCopyright(){
    return this.http.get(`assets/datos-${idioma}/copyright.json`);
  }

  getDataAbout(){
    return this.http.get(`assets/datos-${idioma}/quienes-somos.json`);
  }

  getDataTours(){
    return this.http.get(`assets/datos-${idioma}/tours.json`);
  }

  getDataContact(){
    return this.http.get(`assets/datos-${idioma}/contacto.json`);
  }

  getFormContact(){
    return this.http.get(`assets/datos-${idioma}/form-contacto.json`);
  }

  getDataPackage(){
    return this.http.get(`assets/datos-${idioma}/paquetes.json`);
  }

  getFormReserva(){
    return this.http.get(`assets/datos-${idioma}/form-reserva.json`);
  }

  getDetalleBusqueda(){
    return this.http.get(`assets/datos-${idioma}/detalle-reserva.json`);
  }

  getInfoTourPaquete(){
    return this.http.get(`assets/datos-${idioma}/tour-info.json`);
  }

  getTxtDestinationReservation(){
    return this.http.get(`assets/datos-${idioma}/destination-reservation.json`);
  }

  getTxtMyReservation(){
    return this.http.get(`assets/datos-${idioma}/my-reservacion.json`);
  }

  getTablaComparacion(){
    return this.http.get(`assets/datos-${idioma}/tabla-comparacion.json`);
  }

  getFechaActual(): string{
    const date = new Date,
        dformat = [ date.getFullYear(),
                    this.padLeft(date.getMonth()+1),
                    this.padLeft(date.getDate()),
                    ].join('-');
     return dformat;
  }

  getFechaMinima(): string{
    let fecha = new Date();
    fecha.setDate( fecha.getDate()  + 1 );

    const date = new Date(fecha),
        dformat = [ date.getFullYear(),
                    this.padLeft(date.getMonth()+1),
                    this.padLeft(date.getDate()),
                    ].join('-');
     return dformat;
  }

  getFechaSiguiente( _fecha: any ): string{

    let fecha = new Date( _fecha );
    fecha.setDate( fecha.getDate()  + 2 );

    const date = new Date(fecha),
        dformat = [ date.getFullYear(),
                    this.padLeft(date.getMonth()+1),
                    this.padLeft(date.getDate()),
                    ].join('-');
     return dformat;
  }

  padLeft(n: any){
    return ("00" + n).slice(-2);
  }


  sumarDosValores( _datoUno: any, _datosDos: any ): number {
    return parseInt(_datoUno) + parseInt(_datosDos);
  }


}
