import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { generarReserva, Reservacion } from 'src/app/models/reservacion.model';
import { respPagoPaypal } from './../../models/reservacion.model';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.css']
})
export class ReservationDetailComponent implements OnInit {

  // Asingar en una variable el resultado de la posible reservacion desde el formulario del Home o Modal
  public _pre_reservacion: Reservacion = this.localData.prospectoReserva;
  // Variable para saber si hay datos para proceder con la reserva
  public sePuedeHacerLaReserva: boolean = true;
  // Variable que sera la reserva principal
  public _reservacion_!: generarReserva;
  // Variable para almacenar todos los textos en idioma correcto
  public allTxtDetalle: any;
  //Variable para validar si requiere factura
  public requiereFactura: string = 'NO';
  // Costo de dos terminales
  public costoDosTerminales: number  = this.externalData._Configuracion_.Costo_Dos_Terminales || 0;
  // Inicializar al momento de empezar transacción de pago y cuando los datos sean correctos
  public iniciarPago: boolean =  false;
  // Observable de paises
  paises$!: Observable<any>;
  // Variable donde se almacenara el valor del select de aerolinea llegada
  public viewOtherAirlineLLegada: boolean = false;
  // Variable donde se almacenara el valor del select de aerolinea salida
  public viewOtherAirlineSalida: boolean = false;
  // Objeto de paises
  paises: any;

  _airlines = [
    'Aeromexico',
    'AirTran',
    'Airfrance',
    'Alitalia',
    'American Airlines',
    'Delta',
    'Continental Airlines',
    'Air Canada',
    'Spirit',
    'United',
    'Interjet',
    'Volaris',
    'Vivaaerobus',
    'Copa Airlines',
    'British Airways',
    'Tap Air Portugal',
    'Aerolineas Argentinas',
    'Latam',
    'Avianca',
    'Us Airways',
    'Iberia',
    'JetBlue',
    'Southwest',
  ];


  constructor( private localData: LocalDataServiceService,
               private externalData: ExternalDataServiceService,
               private router: Router ) {

                if( !this._pre_reservacion ) {
                  this.router.navigate(['/home']);
                  return;
                }

                this.paises$ = this.externalData.getPaises();

                this.allTxtDetalle = [];
                this.paises = [];

                this._reservacion_ = new generarReserva();

  }

  ngOnInit(): void {
    this.getTxtDetalle();
    this.getInfoReservacion();
    this.obtenerPaises();
    this.subirScroll();
  }

  getTxtDetalle() {
    this.localData.getDetalleBusqueda().subscribe((data: any) => {
      this.allTxtDetalle= data;
    });
  }

  //Obtener información de una reservacion: precio e información concreta
  getInfoReservacion() {

    this.externalData.getDatosPosibleReserva( this._pre_reservacion ).subscribe((data:any) => {

      if( !data.ok || data.datos[0].Costo_T < 1 ) {
        this.sePuedeHacerLaReserva = false;
        return
      }

      if(this.localData.idiomaActual == 'es') {
        this._reservacion_.Tipo_Moneda = 'MXN';
        this._reservacion_.Idioma = 'es';
      } else {
        this._reservacion_.Tipo_Moneda = 'USD';
        this._reservacion_.Idioma = 'en';
      }

      this._reservacion_.Tipo_Reserva = 'TRANSFER';
      this._reservacion_.Costo =  this.sumarRestar(data.datos[0].Costo_T, data.datos[0].Costo_A, 'S');
      this._reservacion_.Costo_Total = this.sumarRestar(data.datos[0].Costo_T, data.datos[0].Costo_A, 'S');
      this._reservacion_.Traslado = this._pre_reservacion.Traslado;
      this._reservacion_.Traslado_Txt = data.datos[0].Descripcion;
      this._reservacion_.Adultos = this._pre_reservacion.Adultos;
      this._reservacion_.Ninos = this._pre_reservacion.Ninos;
      this._reservacion_.TrasladoInterno = this._pre_reservacion.TrasladoInterno;
      this._reservacion_.Llegada = this._pre_reservacion.Llegada;
      this._reservacion_.Salida = this._pre_reservacion.Salida;
      this._reservacion_.Desde = this._pre_reservacion.Desde;
      this._reservacion_.Hasta = this._pre_reservacion.Hasta;

      if( this._pre_reservacion.Desde_Txt == undefined || this._pre_reservacion.Desde_Txt == '' ) {

        this._reservacion_.Desde_Txt = 'CANCUN AIRPORT/AEROPUERTO';
        this._reservacion_.Hasta_Txt = this._pre_reservacion.Hasta_Txt;

      } else if( this._pre_reservacion.Hasta_Txt == undefined || this._pre_reservacion.Hasta_Txt == '' ) {

        this._reservacion_.Desde_Txt = this._pre_reservacion.Desde_Txt
        this._reservacion_.Hasta_Txt = 'CANCUN AIRPORT/AEROPUERTO';

      } else {

        this._reservacion_.Desde_Txt = this._pre_reservacion.Desde_Txt
        this._reservacion_.Hasta_Txt = this._pre_reservacion.Hasta_Txt;

      }


    });
  }


  validarLlegadaMismaTerminal( event: any ) {

    if( this._reservacion_.LLegada_Dos_Terminales == 'SI' ) {
        this._reservacion_.Costo_Total = this.sumarRestar(this._reservacion_.Costo_Total!, this.costoDosTerminales, 'R');
      } else if( this._reservacion_.LLegada_Dos_Terminales == 'NO' ){
      this._reservacion_.Costo_Total = this.sumarRestar(this._reservacion_.Costo_Total!, this.costoDosTerminales, 'S');
    }

    this._reservacion_.LLegada_Dos_Terminales = event.target.value;

  }

  validarSiRequiereFactura( event: any ) {

    let dato = event.target.value;

    if(dato == 'SI') {
      this.requiereFactura = 'NO';
    }

    if( dato == 'NO' ){
      this.requiereFactura = 'SI';
    }

    this._reservacion_.Nombre_Factura = '';
    this._reservacion_.Direccion_Factura = '';
    this._reservacion_.Ciudad_Factura = '';
    this._reservacion_.Estado_Factura = '';
    this._reservacion_.Codigo_Postal = '';

  }

  validarDatos(): boolean{

    //console.log( 'Tipo traslado: ' +  this._pre_reservacion.TrasladoInterno );

    if( this._reservacion_.Nombre_Pasajero === '' ||  this._reservacion_.Apellido_Pasajero === '' || this._reservacion_.Email_Pasajero == '' || !this.emailCorrecto() || this._reservacion_.Nombre_Pasajero == '' || this._reservacion_.Telefono_Pasajero == '' || this._reservacion_.Telefono_Pasajero == null || this._reservacion_.Pais_Pasajero === '' || this._reservacion_.Pais_Pasajero === null || this._reservacion_.Numero_Cuarto == null )
    {
          //console.log( 'Algo mal en datos personales importantes' )
          return false;
    }

    else if( (this._pre_reservacion.TrasladoInterno === 'RTAH' || this._pre_reservacion.TrasladoInterno === 'OWAH') && (this._reservacion_.Aerolina_Llegada == '' || this._reservacion_.Codigo_Vuelo_LLegada == '' || this._reservacion_.Hora_Vuelo_LLegada == '') )
    {
        //console.log('Algo mal en los campos importantes de vuelo de recepcion RTAH-OWAH')
        return false;

    }

    else if( (this._pre_reservacion.TrasladoInterno === 'RTAH' || this._pre_reservacion.TrasladoInterno === 'OWHA') && (this._reservacion_.Aerolina_Salida == '' || this._reservacion_.Codigo_Vuelo_Salida == '' || this._reservacion_.Hora_Vuelo_Salida == ''))
    {
      //console.log('Algo mal en los campos importantes de vuelo de salida RTAH-OWHA')
      return false;
    }

    else if( (this._pre_reservacion.TrasladoInterno === 'RTHH' ) && this._reservacion_.Hora_Vuelo_LLegada == '')
    {
      //console.log('Algo mal en los campos importantes de HORA RECEPCION RTHH - OWHH ')
      return false;
    }

    else if( (this._pre_reservacion.TrasladoInterno === 'RTHH' || this._pre_reservacion.TrasladoInterno === 'OWHH') && this._reservacion_.Hora_Vuelo_Salida == '')
    {
      //console.log('Algo mal en los campos importantes de HORA SALIDA RTHH ')
      return false;
    }

    else if( this.requiereFactura == 'SI' && ( this._reservacion_.Correo_Factura === ''|| this._reservacion_.Rfc_Factura === '' || this._reservacion_.Cfdi_Factura === '' || this._reservacion_.Nombre_Factura === '' || this._reservacion_.Direccion_Factura === '' ||  this._reservacion_.Ciudad_Factura == '' || this._reservacion_.Estado_Factura == '' || this._reservacion_.Codigo_Postal == '' || this._reservacion_.Codigo_Postal == null) )
    {
      ////console.log('Algo mal en los campos de facturacion')
      return false;
    }

    else
    {
      ////console.log('Excelente todo bien 10/10');
      return true;
    }

  }

  sumarRestar( numeroUno: any, numeroDos: any, operacion: string ): number {
    if( operacion == 'R' ) {
      return parseInt(numeroUno) - parseInt(numeroDos);
    } else {
      return parseInt(numeroUno) + parseInt(numeroDos);
    }
  }

  procederPago() {
    if( this.iniciarPago ) {
      this.iniciarPago = false;
    } else {
      this.iniciarPago = true;
    }
  }

  guardarReservacion( _respuestapaypal_: respPagoPaypal ) {

    this._reservacion_.IdOrden = _respuestapaypal_.idOrden;
    this._reservacion_.FechaOrden = _respuestapaypal_.fechaOrden;
    this._reservacion_.StatusOrden = _respuestapaypal_.statusOrden ;
    this._reservacion_.EmailPayer = _respuestapaypal_.emailPayer ;
    this._reservacion_.CiudadCodePayer = _respuestapaypal_.ciudadCodePayer ;
    this._reservacion_.NombrePayer = _respuestapaypal_.nombrePayer ;
    this._reservacion_.IdMerchant = _respuestapaypal_.idMerchant ;
    this._reservacion_.StatusCaptura = _respuestapaypal_.statusCaptura ;
    this._reservacion_.IdCaptura = _respuestapaypal_.idCaptura ;
    this._reservacion_.FormaPago = 'PAYPAL';
    this._reservacion_.MetodoPago = 'PAYPAL';
    this._reservacion_.LugarPago = 'EITUR.MX';
    this._reservacion_.LugarReserva = 'EITUR.MX';

    if( _respuestapaypal_.ok && (_respuestapaypal_.idMerchant != null || _respuestapaypal_.idMerchant != '' || _respuestapaypal_.idMerchant != undefined) ) {

      this.externalData.postAddReservacion( this._reservacion_ ).subscribe( (data: any) => {

        this.subirScroll();

        if( data.ok ) {

            Swal.fire({
              title: 'Correct',
              html: `<p style="font-size:18px;text-align:center">${data.message}</p><p style="font-size:15px; color: red"> ${ data.messageDos } </p>`,
              icon: 'success',
              confirmButtonText: 'Acept',
              confirmButtonColor: 'green'
            }).then((result) => {
              if (result.value) {
                location.reload();

              }
            });

        } else {

          Swal.fire('Error', data.message , 'error');

        }

      });


    } else {
      this.subirScroll();
      Swal.fire('Error', _respuestapaypal_.respuesta , 'error')
    }

  }

  guardarParaPagoLlegada( ) {

    this.localData.isLoading = true;


    this._reservacion_.IdOrden = '';
    this._reservacion_.FechaOrden = '';
    this._reservacion_.StatusOrden = '';
    this._reservacion_.EmailPayer = '' ;
    this._reservacion_.CiudadCodePayer = '' ;
    this._reservacion_.NombrePayer = '' ;
    this._reservacion_.IdMerchant = '';
    this._reservacion_.StatusCaptura = 'PENDIENTE';
    this._reservacion_.IdCaptura = '';

    this._reservacion_.LugarPago = 'RECEPCION';
    this._reservacion_.LugarReserva = 'EITUR.MX';


    this.externalData.postAddReservacion( this._reservacion_ ).subscribe( (data: any) => {
      this.subirScroll();

      if( data.ok ) {
          Swal.fire({
            title: 'Correct',
              html: `<p style="font-size:18px;text-align:center">${data.message}</p><p style="font-size:15px; color: red"> ${ data.messageDos } </p>`,
              icon: 'success',
              confirmButtonText: 'Acept',
              confirmButtonColor: 'green'
          }).then((result) => {
            if (result.value) {

              location.reload();

            }
          });

      } else {

        Swal.fire('Error', data.message , 'success');

      }

    });

  }


  emailCorrecto(  ): boolean {

    const valor = this._reservacion_.Email_Pasajero;

    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //Se muestra un texto a modo de ejemplo, luego va a ser un icono
    if (emailRegex.test( valor || '' )) {
      return true;
    } else {
      return false;
    }
  }


  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 2000);
  }

  aplicarCupon( datos: any ) {

    this._reservacion_.Codigo_Descuento = datos.Codigo;
    this._reservacion_.Costo_Descuento = datos.Porcentaje;

    const variablePorcentaje = datos.Porcentaje / 100;
    const costoPorcentaje = this._reservacion_.Costo! * variablePorcentaje;

    this._reservacion_.Costo_Total =  Math.round(this._reservacion_.Costo! - costoPorcentaje);

  }

  obtenerPaises() {
    this.paises = this.paises$.subscribe( (datos: any) => {
      this.paises = datos.data.sort();
    });
  }

  changeAirlineLlegada( event: any ) {
    if( event.target.value == 'Otro' ) {
      this.viewOtherAirlineLLegada = true;
      this._reservacion_.Aerolina_Llegada = '';
    } else {
      this.viewOtherAirlineLLegada = false;
      this._reservacion_.Aerolina_Llegada = event.target.value;
    }
  }

  changeAirlineSalida( event: any ) {
    if( event.target.value == 'Otro' ) {
      this.viewOtherAirlineSalida = true;
      this._reservacion_.Aerolina_Salida = '';
    } else {
      this.viewOtherAirlineSalida = false;
      this._reservacion_.Aerolina_Salida = event.target.value;
    }
  }


}
