import { Component, Input, OnInit } from '@angular/core';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';

@Component({
  selector: 'app-txt-descrip-package',
  templateUrl: './txt-descrip-package.component.html',
  styleUrls: ['./txt-descrip-package.component.css']
})
export class TxtDescripPackageComponent implements OnInit {

  @Input() idPaquete: any;

  dataTxt: any;

  constructor( private externalData: ExternalDataServiceService ) { 
    this.dataTxt = [];
  }

  ngOnInit(): void {
    this.externalData.getPaquetesDetalle( this.idPaquete ).subscribe( (data: any) => {
      this.dataTxt = data.paquetes;
    });
  }

}
