import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Configuracion } from 'src/app/models/configuracion.model';

const idioma = environment.idioma;

@Component({
  selector: 'app-tours-tours',
  templateUrl: './tours-tours.component.html',
  styleUrls: ['./tours-tours.component.css']
})
export class ToursToursComponent implements OnInit {

  @Input() title: string = '';
  @Input() titleSub: string = '';
  @Input() titleBtn: string = '';

  public dataTours: any;
  public moneda:string = 'MXN';
  public apiUrl: any = this.externalData._apirest;
  public showPrice: any;

  constructor( private externalData: ExternalDataServiceService ) {
    if( idioma === 'en') {
      this.moneda = 'USD';
    }

  }

  async ngOnInit() {
    this.externalData.getTours().subscribe( (data: any) => {
      this.dataTours = data.tours;
    });
    this.showPrice = this.externalData._Configuracion_;
  }

  getImagen( path: string ): string {
    return  path == 'no-image' ? `assets/img/no-image.png` : `${this.apiUrl}/${path}`;
  }

}
