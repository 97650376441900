import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExternalDataServiceService } from './../../../services/external-data-service.service';

@Component({
  selector: 'app-carousel-banner',
  templateUrl: './carousel-banner.component.html',
  styleUrls: ['./carousel-banner.component.css']
})
export class CarouselBannerComponent implements OnInit {

  @Input() public idPagina: any = '';
  @Output() _ejecutarFuncion_ = new EventEmitter<boolean>();


  public datosDelBanner: any[];

  constructor( private externalData: ExternalDataServiceService ) {

    this.datosDelBanner = [];

  }

  ngOnInit(): void {
    //console.log( this.idPagina )
    this.externalData.getBanners(this.idPagina).subscribe( (data: any) => {
      this.datosDelBanner = data.banners;
    });
  }

  ejecutaFuncion() {
    this._ejecutarFuncion_.emit( true );
  }

  getImg( url: string ): string {
    return `assets/img/${url}`;
  }

}
