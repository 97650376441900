import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

const idioma = environment.idioma;

@Component({
  selector: 'app-chatboot',
  templateUrl: './chatboot.component.html',
  styleUrls: ['./chatboot.component.css']
})
export class ChatbootComponent implements OnInit {

  Tawk_API: any;
  Tawk_LoadStart: any;
  urlTawk: string;


  constructor() { 
    if( idioma == 'es' ) {
      this.urlTawk = 'https://embed.tawk.to/610f7ce2d6e7610a49af23b1/1fci7vl0u';
    } else {
      this.urlTawk = 'https://embed.tawk.to/610f7ce2d6e7610a49af23b1/1fckk3g3d';
    }
  }

  ngOnInit(): void {
    
    this.Tawk_API = this.Tawk_API || {}, this.Tawk_LoadStart = new Date();
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src = this.urlTawk;
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode!.insertBefore(s1,s0);

  }

}
