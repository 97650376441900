<div class="container-xl contenido"  style="height: auto; width: 100%;">
    <div class="row" >

      <h5 style="font-size: 15px;margin: 0px;font-weight: bold;margin-bottom: 10px;margin-top: 15px;" >{{ txtForm.IMPORTANT }}</h5>

        <div class="col-md-4" *ngIf="prospectoReserva">

            <h5 style="font-size: 20px;margin: 0px;font-weight: bold;margin-bottom: 18px;" > {{ txtForm.TRESER }} :</h5>

            <div class="bg-reserva">
                <div style="text-align: center; margin-bottom: 15px;">
                    <img src="{{apiUrl}}/{{ prospectoReserva.Img }}" style="width: 70%;margin-bottom: 10px;margin-top: 15px;border-radius: 15px;">
                </div>

                <h5 style="font-size: 14px;color:#ffffff;" > {{ txtForm.DESTIN }}: <span style="color: #d3ccccec;"> {{ prospectoReserva.Txt_Destino | uppercase }} </span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">
                <h5 style="font-size: 14px;color:#ffffff;" > {{ txtForm.FECHA }}: <span style="color: #d3ccccec;"> {{ prospectoReserva.Fecha }} </span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">
                <h5 style="font-size: 14px;color:#ffffff;" > {{ txtForm.ADULTOS }}: <span style="color: #d3ccccec;"> {{ prospectoReserva.Adultos }} </span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">
                <h5 style="font-size: 14px;color:#ffffff;" > {{ txtForm.NINOS }}: <span style="color: #d3ccccec;"> {{ prospectoReserva.Ninos }} </span> </h5>
                <hr style="margin-top:5px;margin-bottom:5px;">

                <div style="text-align: center;margin-top: 30px;">
                  <p style="font-weight: bold;font-size: 60px;color: #ffffff;">${{ prospectoReserva.Costo_Total | number : '1.0-0'}} <span style="font-size: 40px;" >{{ txtForm.MONEDA }}</span></p>
                </div>

            </div>

            <div style="text-align: center;">
                <br>
                <p *ngIf="!validarDatos()" style="color: yellowgreen;margin-top: 25px;font-size: 15px;font-weight: bold;"> * {{ txtForm.LLENACPS }} </p>
                <!--<button *ngIf="!iniciarPago" [disabled]="!validarDatos()" (click)="procederPago()" type="button" class="btn btn-success" style="width: 100%"> {{ txtForm.METPAGO }} <i class="material-icons" style="margin-top:-5px;margin-bottom:0 ">payments</i> </button>-->
                <!--<button *ngIf="iniciarPago" (click)="procederPago()" type="button" class="btn btn-link" style="width: 100%;margin-bottom: 5px;font-size: 15px;"> {{ txtForm.EDITAR }} </button>-->
                <!--<app-btn-paypal *ngIf="iniciarPago" [_getreserva_]="_reservacion_" (_cargarPagina_)="cargarLoading($event)" (_respReserva_)="guardarReservacion($event)"></app-btn-paypal>-->
                <button [disabled]="!validarDatos()" (click)="guardarParaPagoLlegada()" type="button" class="btn btn-success" style="width: 100%; font-family: Champagne; "> {{ txtForm.BTNRESERVA }}</button>

            </div>

        </div><!-- FIN COL-MD-4 -->



        <div class="col-md-8" >
            <!-- FORMULARIO DEL PASAJERO-->
            <h5 style="font-size: 20px;margin: 0px;font-weight: bold;margin-bottom: 18px;" > {{ txtForm.TDPASAJERO }}:</h5>
            <hr style="margin-top:5px;margin-bottom:2px;">
            <h6 style="font-size: 12px;font-family: Champagne" >{{ txtForm.DESTPASAJE }}</h6>

            <div class="contDatos row" >

                <!-- Datos personales -->
                <div class="col-md-6" >
                    <label> {{ txtForm.NOMBRE }}: </label>
                    <input [readonly]="iniciarPago" [placeholder]="txtForm.NOMBRE" autocomplete="off" type="text" class="form-control" [(ngModel)]="_reservacion_.Nombre_Pasajero">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Nombre_Pasajero === '' || _reservacion_.Nombre_Pasajero === undefined"> * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-6" >
                    <label> {{ txtForm.APELLIDO }}: </label>
                    <input [readonly]="iniciarPago" [placeholder]="txtForm.APELLIDO" autocomplete="off" type="text" class="form-control" [(ngModel)]="_reservacion_.Apellido_Pasajero">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Apellido_Pasajero === '' || _reservacion_.Apellido_Pasajero === undefined " > * {{ txtForm.LLENAIPT }} </p>
                </div>
                <!-- END Datos personales -->


                <!-- Seccion para mas datos del pasajero -->
                <div class="col-md-6" >
                    <label>{{ txtForm.EMAIL }}: </label>
                    <input [readonly]="iniciarPago" [placeholder]="txtForm.EMAIL" autocomplete="off" type="email" class="form-control" [(ngModel)]="_reservacion_.Email_Pasajero">
                    <p class="txt-incorrecto" *ngIf="!emailCorrecto() || _reservacion_.Email_Pasajero ==='' || _reservacion_.Email_Pasajero === undefined"> * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-6" >
                    <label>{{ txtForm.TELEFONO }}: </label>
                    <input [readonly]="iniciarPago" [placeholder]="txtForm.TELEFONO" autocomplete="off" type="number" class="form-control" [(ngModel)]="_reservacion_.Telefono_Pasajero">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Telefono_Pasajero === '' || _reservacion_.Telefono_Pasajero == undefined " > * {{ txtForm.LLENAIPT }} </p>
                </div>
                <!-- END Seccion para mas datos del pasajero -->

                <div class="col-md-12" >
                    <label>{{ txtForm.RECEPCIO }}: </label>
                    <app-input-search [habilitado]="iniciarPago" (pushData)="cambiarValorDesde( $event )" (pushDataTxt)="cambiarValorDesdeTxt( $event )" [placeholder]="txtForm.RECEPCIO" class="iptSearch" ></app-input-search>
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Desde_Txt === undefined || _reservacion_.Desde_Txt === '' || _reservacion_.Desde_Txt === null " > * {{ txtForm.LLENAIPT }} </p>
                </div>



                <!-- Seccion para pais y numero de habitacion -->
                <div class="col-md-6" >
                    <label>{{ txtForm.PAIS }}: </label>
                    <select [disabled]="iniciarPago" class="form-control" [(ngModel)]="_reservacion_.Pais_Pasajero">
                        <option value=""> {{ txtForm.SELECTPAIS }} </option>
                        <option [value]="txtForm.OPTMEX"> {{ txtForm.OPTMEX }} </option>
                        <option [value]="txtForm.OPTUSD"> {{ txtForm.OPTUSD }}</option>
                        <option [value]="txtForm.OPTCND"> {{ txtForm.OPTCND }} </option>
                        <option *ngFor="let p of paises" [value]="p" > {{ p }} </option>
                    </select>
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Pais_Pasajero === '' || _reservacion_.Pais_Pasajero === null" > * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-6" >
                    <label>{{ txtForm.CUARTO }}: </label>
                    <input autocomplete="off" [readonly]="iniciarPago" type="number" class="form-control" [placeholder]="txtForm.CUARTO" [(ngModel)]="_reservacion_.Numero_Cuarto">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Numero_Cuarto == null" > * {{ txtForm.LLENAIPT }} </p>
                </div>
                <!-- END Seccion para pais y numero de habitacion -->





                <!-- Comentarios del pasajero -->
                <div class="col-md-12" >
                    <label>{{ txtForm.COMENT }}: </label>
                    <textarea [readonly]="iniciarPago" [placeholder]="txtForm.COMENT" style="height: 50px !important;" class="form-control" [(ngModel)]="_reservacion_.Comentarios_Reserva"></textarea>
                </div>
                <!-- END Comentarios del pasajero -->

                <div class="col-md-12" style="margin-top: 15px;text-align: right;">
                    <div class="form-check form-check-inline">
                        <input [disabled]="iniciarPago" autocomplete="off" class="form-check-input" type="checkbox" id="inlineCheckbox1" [checked]="requiereFactura==='SI'" (change)="validarSiRequiereFactura( $event )" [value]="requiereFactura">
                        <label class="form-check-label" for="inlineCheckbox1" style="color: #00659f;">* {{ txtForm.RFACTURA }}</label>
                    </div>
                </div>
                <!--END VALIDACIONES SI VIENEN EN UNA MISMA TERMINAL O SI REQUIERE FACTURA-->

            </div>

            <!---- FORMULARIO PARA FACTURACION -->
            <br>
            <h5 style="font-size: 20px;margin: 0px;font-weight: bold;margin-bottom: 18px;" >{{ txtForm.TFACTURA }}:</h5>
            <hr style="margin-top:5px;margin-bottom:2px;">
            <h6 style="font-size: 12px;font-family: Champagne" > {{ txtForm.DFACTURA }} </h6>

            <div class="contDatos row" >

                <div class="col-md-6" >
                    <label>{{ txtForm.FIPTNOMB }}:</label>
                    <input [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" [placeholder]="txtForm.FIPTNOMB" autocomplete="off" type="text" [(ngModel)]="_reservacion_.Nombre_Factura" class="form-control" >
                    <p class="txt-incorrecto" *ngIf="requiereFactura==='SI' && (_reservacion_.Nombre_Factura==='' || _reservacion_.Nombre_Factura===undefined)" > * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-6" >
                    <label>{{ txtForm.FIPTDIRE }}:</label>
                    <input [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" [placeholder]="txtForm.FIPTDIRE" autocomplete="off" type="text" [(ngModel)]="_reservacion_.Direccion_Factura" class="form-control">
                    <p class="txt-incorrecto" *ngIf="requiereFactura==='SI' && (_reservacion_.Direccion_Factura==='' || _reservacion_.Direccion_Factura===undefined)" > * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-4" >
                    <label>{{ txtForm.FIPTCORREO }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="text" [(ngModel)]="_reservacion_.Correo_Factura" class="form-control" [placeholder]="txtForm.FIPTCORREO">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Correo_Factura === '' && requiereFactura=='SI'" > * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-4" >
                    <label>{{ txtForm.FIPTRFC }}:</label>
                    <input autocomplete="off" [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" type="text" [(ngModel)]="_reservacion_.Rfc_Factura" class="form-control" [placeholder]="txtForm.FIPTRFC">
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Rfc_Factura === '' && requiereFactura=='SI'" > * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-4" >
                    <label>{{ txtForm.FIPTCFDI }}:</label>
                    <select [(ngModel)]="_reservacion_.Cfdi_Factura" [disabled]="iniciarPago  || requiereFactura=='NO'" class="form-control" >
                        <option value=""> {{ txtForm.SELECTIONAR }} </option>
                        <option [value]="txtForm.USO1"> {{ txtForm.USO1 }} </option>
                        <option [value]="txtForm.USO2"> {{ txtForm.USO2 }} </option>
                        <option [value]="txtForm.USO3"> {{ txtForm.USO3 }} </option>
                        <option [value]="txtForm.USO4"> {{ txtForm.USO4 }} </option>
                        <option [value]="txtForm.USO5"> {{ txtForm.USO5 }} </option>
                        <option [value]="txtForm.USO6"> {{ txtForm.USO6 }} </option>
                        <option [value]="txtForm.USO7"> {{ txtForm.USO7 }} </option>
                        <option [value]="txtForm.USO8"> {{ txtForm.USO8 }} </option>
                        <option [value]="txtForm.USO9"> {{ txtForm.USO9 }} </option>
                        <option [value]="txtForm.USO10"> {{ txtForm.USO10 }} </option>
                        <option [value]="txtForm.USO11"> {{ txtForm.USO11 }} </option>
                        <option [value]="txtForm.USO12"> {{ txtForm.USO12 }} </option>
                        <option [value]="txtForm.USO13"> {{ txtForm.USO13 }} </option>
                        <option [value]="txtForm.USO14"> {{ txtForm.USO14 }} </option>
                        <option [value]="txtForm.USO15"> {{ txtForm.USO15 }} </option>
                        <option [value]="txtForm.USO16"> {{ txtForm.USO16 }} </option>
                        <option [value]="txtForm.USO17"> {{ txtForm.USO17 }} </option>
                        <option [value]="txtForm.USO18"> {{ txtForm.USO18 }} </option>
                        <option [value]="txtForm.USO19"> {{ txtForm.USO19 }} </option>
                        <option [value]="txtForm.USO20"> {{ txtForm.USO20 }} </option>
                        <option [value]="txtForm.USO21"> {{ txtForm.USO21 }} </option>
                        <option [value]="txtForm.USO22"> {{ txtForm.USO22 }} </option>
                    </select>
                    <p class="txt-incorrecto" *ngIf="_reservacion_.Cfdi_Factura === '' && requiereFactura=='SI'" > * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-4" style="margin-top: 15px;">
                    <label>{{ txtForm.FIPTCIUD }}:</label>
                    <input [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" [placeholder]="txtForm.FIPTCIUD" autocomplete="off" type="text" [(ngModel)]="_reservacion_.Ciudad_Factura" class="form-control">
                    <p class="txt-incorrecto" *ngIf="requiereFactura==='SI' && (_reservacion_.Ciudad_Factura==='' || _reservacion_.Ciudad_Factura===undefined)" > * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-5" style="margin-top: 15px;">
                    <label>{{ txtForm.FIPTESTA }}:</label>
                    <input  [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" [placeholder]="txtForm.FIPTESTA" autocomplete="off" type="text" [(ngModel)]="_reservacion_.Estado_Factura" class="form-control">
                    <p class="txt-incorrecto" *ngIf="requiereFactura==='SI' && (_reservacion_.Estado_Factura==='' || _reservacion_.Estado_Factura===undefined)" > * {{ txtForm.LLENAIPT }} </p>
                </div>

                <div class="col-md-3" style="margin-top: 15px;">
                    <label>{{ txtForm.FIPTCODP }}:</label>
                    <input [readonly]="iniciarPago" [disabled]="requiereFactura=='NO'" [placeholder]="txtForm.FIPTCODP" autocomplete="off" type="number" [(ngModel)]="_reservacion_.Codigo_Postal" class="form-control">
                    <p class="txt-incorrecto" *ngIf="requiereFactura==='SI' && (_reservacion_.Codigo_Postal==='' || _reservacion_.Codigo_Postal===null || _reservacion_.Codigo_Postal===undefined)" > * {{ txtForm.LLENAIPT }} </p>
                </div>

            </div>

        </div> <!--FIN COL-MD-8-->


    </div>
</div>
