import { Component, OnInit } from '@angular/core';
import { LocalDataServiceService } from '../../services/local-data-service.service';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { Configuracion } from 'src/app/models/configuracion.model';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.css']
})
export class PageComponent implements OnInit {

  constructor( public localService: LocalDataServiceService) {
  }

  ngOnInit(): void {
  }

}
