import { Component, OnInit } from '@angular/core';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-tours',
  templateUrl: './tours.component.html',
  styleUrls: ['./tours.component.css']
})
export class ToursComponent implements OnInit {

  public txtTours: any;

  constructor( private localData: LocalDataServiceService,
               private externalData: ExternalDataServiceService) {
    this.txtTours = [];
  }

  ngOnInit(): void {
    this.localData.isLoading = true;
    this.localData.getDataTours().subscribe( data => {
      this.txtTours = data;
    });

    this.subirScroll();
  }

  ejecutarBtnCarrousel( event: any ) {
    window.scroll(0,300);
  }


  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 2500);
  }

}
