import { Component, OnInit } from '@angular/core';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { environment } from 'src/environments/environment';

const idioma = environment.idioma;
@Component({
  selector: 'app-package',
  templateUrl: './package.component.html',
  styleUrls: ['./package.component.css']
})
export class PackageComponent implements OnInit {

  public dataTxtPaquetes: any;
  public dataPaquetes: any;
  public moneda:string = 'MXN';
  public apiUrl: any = this.externalData._apirest;
  public showPrice: any;

  constructor( private localData: LocalDataServiceService,
               private externalData: ExternalDataServiceService ) {
    if( idioma === 'en') {
      this.moneda = 'USD';
    }
    this.dataTxtPaquetes = [];
    this.dataPaquetes = [];
  }

  ngOnInit(): void {
    this.localData.isLoading = true;
    this.localData.getDataPackage().subscribe( data => {
      this.dataTxtPaquetes = data;
    });
    this.showPrice = this.externalData._Configuracion_;
    this.getDataPaquetes();
    this.subirScroll();
  }

  getDataPaquetes() {
    this.externalData.getPaquetes().subscribe( (data: any) => {
      this.dataPaquetes = data.paquetes;
    });
  }

  ejecutarBtnCarrousel( event: any ) {
    window.scroll(0,300);
  }

  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 2500);
  }

  getImagen( path: string ): string {
    return  path == 'no-image' ? `assets/img/no-image.png` : `${this.apiUrl}/${path}`;
  }


}
