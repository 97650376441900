import { correoContacto } from './../models/reservacion.model';
import { consultaReserva } from './../models/consulta-reservacion.model';
import { Configuracion } from './../models/configuracion.model';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Destinos } from 'src/app/models/destinos.model';
import { generarReserva, Reservacion } from 'src/app/models/reservacion.model';

const idioma = environment.idioma;
const apirest = environment.urlrest

@Injectable({
  providedIn: 'root'
})
export class ExternalDataServiceService {

  _Configuracion_: Configuracion;

  _apirest: any;

  constructor( private http: HttpClient ) {
    this._Configuracion_ = new Configuracion;
    this._apirest = apirest;
  }

  getBanners( _pagina: any ){
    return this.http.post(`${apirest}/getBanners`,{ idioma :idioma, pagina: _pagina });
  }

  getToursHome(){
    return this.http.get(`${apirest}/tours/home/${idioma}`);
  }

  getComentariosHome(){
    return this.http.post(`${apirest}/getComentarios`,{ idioma: idioma });
  }

  getTours(){
    return this.http.get(`${apirest}/tours/${idioma}`);
  }

  getPaquetes(){
    return this.http.get(`${apirest}/paquetes/${idioma}`);
  }

  getPaquetesDetalle( _paquete: any ){
    return this.http.post(`${apirest}/paquetes/detalle`, { paquete: _paquete, idioma: idioma });
  }

  //Obtenemos todos los destinos
  getDestinos(): Observable<Destinos>{
    return this.http.get<Destinos>(`${apirest}/destinos`);
  }

  //Obtenemos datos de la posible reserva
  getDatosPosibleReserva( _prospectoReserva: Reservacion ) {
    return this.http.post(`${apirest}/busqueda-posible-reserva`, _prospectoReserva);
  }

  //Obtenemos datos del codigo promocional como vigencia
  getPreaplicaPromocion( _codigo: string, _tipo: string, _traslado: string, _detalle_traslado: string , _totalPax: number, _desde: number, _hasta: number ) {
    return this.http.post(`${apirest}/validacion-codigo-promocion`, { Codigo: _codigo, Tipo: _tipo, Traslado: _traslado, Detalle_Traslado: _detalle_traslado, NumPax: _totalPax, Desde: _desde, Hasta: _hasta });
  }

  //Guardar datos de la reserva
  postAddReservacion( _reserva: generarReserva ){
    return this.http.post(`${apirest}/reservacion`, _reserva );
  }

  //Obtener todos los datos de configuracion
  getConfiguracion() {
    return this.http.post(`${apirest}/configuracion`, { Idioma: idioma } );
  }

  //Post para obtener los detalles del tour y paquete
  postGetDetalleTourPaquete( _destino: number, _tipo: string ){
    return this.http.post(`${apirest}/paquete-tour/detalle`, { Idioma: idioma, Destino: _destino, Tipo: _tipo } );
  }

  //Post para filtrar una reservacion
  postGetOnlyReservacion( _consulta: consultaReserva ){
    return this.http.post(`${apirest}/my-reservacion`, _consulta );
  }

  //Post para enviar correo de contacto
  postSendCorreoContacto( _mensaje: correoContacto ){
    return this.http.post(`${apirest}/send-contacto`, _mensaje );
  }


  /////CUPONES/////

  //Post para obtener los datos de cupon
  postGetDatosCupon( _fecha: string, _codigo: string ){
    return this.http.post(`${apirest}/cupon-datos`, { Fecha: _fecha, Codigo: _codigo } );
  }

   //Post para obtener los datos de cupon
   postAplicarElCupon( _codigo: string ){
    return this.http.post(`${apirest}/cupon-aplicar`, { Idioma: idioma , Codigo: _codigo } );
  }

  //Obtenemos todos los paises
  getPaises() {
    return this.http.post(`${apirest}/paises`,{ idioma: idioma });
  }

}
