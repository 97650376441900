<ng-autocomplete
[itemTemplate]="itemTemplate"
[notFoundTemplate]="notFoundTemplate"
notFoundText="No existe este destino"
[disabled]="habilitado"
[initialValue]="datoPorDefault"
>
</ng-autocomplete>

<ng-template #itemTemplate let-item>
  <a style="font-size: 11px !important;" [innerHTML]="item.Nombre"></a>
</ng-template>
  
<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>

