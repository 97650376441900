import { Component, OnInit } from '@angular/core';
import { correoContacto } from 'src/app/models/reservacion.model';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-footer-secondary',
  templateUrl: './footer-secondary.component.html',
  styleUrls: ['./footer-secondary.component.css']
})
export class FooterSecondaryComponent implements OnInit {

  txtFormContact: any;
  _mcorreo_: correoContacto;
  enviandoMensaje: boolean = false;
  correctoMensaje: boolean = false;
  errorMensaje: boolean = false;


  constructor( private localData: LocalDataServiceService,
    private externalData: ExternalDataServiceService ) { 
    this.txtFormContact = [];
    this._mcorreo_ = new correoContacto();

  }

  ngOnInit(): void {
    this.localData.getFormContact().subscribe( data => {
      this.txtFormContact = data;
    })
  }

  sendCorreo() {

    this.enviandoMensaje = true;

    this.externalData.postSendCorreoContacto( this._mcorreo_ ).subscribe( (data: any) => {
      
      if( data.ok ) {
        this.mostrarMensajeCorrecto();
      } else {
        this.mostrarMensajeError();
      }

      this.enviandoMensaje = false;

    });
  }


  mostrarMensajeCorrecto() {
    this.correctoMensaje = true;
    this._mcorreo_ = new correoContacto();
    setTimeout(() => {
      this.correctoMensaje = false;
    }, 3000);
  }

  mostrarMensajeError() {
    this.errorMensaje = true;
    setTimeout(() => {
      this.errorMensaje = false;
    }, 3000);
  }

}
