import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  displayModal: boolean = false;
  displayImg: boolean = false;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {

      this.displayModal = true;
      this.validarCarga();

    }, 3500);
  }

  closeModal() {
    this.displayModal = false;
  }

  validarCarga() {
    const img = (document.querySelector('img') as HTMLImageElement );
    let isloaded = img.complete && img.naturalHeight !== 0;
    if( isloaded ) {
      this.displayImg = true;
    } else {
      this.validarCarga();
    }
  }

}
