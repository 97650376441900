export class Reservacion {
    public Id?: number;
    public Desde?: number = 1;
    public Desde_Txt?: string;
    public Hasta?: number = 0;
    public Hasta_Txt?: string;
    public Llegada?: string = '';
    public Salida?: string  = '';
    public Adultos?: string = '1';
    public Ninos?: string = '0';
    public Traslado?: string = 'RT';
    public TrasladoInterno?: string = 'RTAH';
    public Idioma?: string;
}

export class ReservacionTour {
    public Id?: number;
    public Txt_Destino?: string;
    public Fecha?: string  = '';
    public Adultos?: string = '1';
    public Ninos?: string = '0';
    public Idioma?: string;
    public Tipo?: string;
    public Costo?: number = 0;
    public Costo_Total?: number = 0;
    public Img?: string = '';
}

export class generarReserva {
    
    public Id?: number;
    public Tipo_Reserva?: string;
    public Desde?: number;
    public Desde_Txt?: string;
    public Hasta?: number;
    public Hasta_Txt?: string;
    public Llegada?: string = '';
    public Salida?: string = '';
    public Adultos?: string;
    public Ninos?: string;
    public Traslado?: string;
    public Traslado_Txt?: string;
    public TrasladoInterno?: string;
    public Idioma?: string;
    public Nombre_Pasajero?: string = '';
    public Apellido_Pasajero?: string = '';
    public Aerolina_Llegada?: string = '';
    public Codigo_Vuelo_LLegada?: string = '';
    public Hora_Vuelo_LLegada?: string = '';
    public Aerolina_Salida?: string = '';
    public Codigo_Vuelo_Salida?: string = '';
    public Hora_Vuelo_Salida?: string = '';

    public Pais_Pasajero?: string = '';
    public Numero_Cuarto?: number;
    
    public Email_Pasajero?: string = '';
    public Telefono_Pasajero?: string = '';
    public Comentarios_Reserva?: string = '';
    public LLegada_Dos_Terminales?: string = 'NO';
    public Requiere_Factura?: string = 'NO';

    public Nombre_Factura?: string = '';
    public Rfc_Factura?: string = '';
    public Cfdi_Factura?: string = '';
    public Correo_Factura?: string = '';
    public Direccion_Factura?: string = '';
    public Ciudad_Factura?: string = '';
    public Estado_Factura?: string = '';
    public Codigo_Postal?: string = '';
    
    public Costo?: number;
    public Costo_Descuento?: number;
    public Codigo_Descuento?: string = '';
    public Costo_Total?: number;
    public Tipo_Moneda?: string = '';
    public Fecha?: string;
    public Hora?: string;
    public Status?: string;
    
    public IdOrden?: string;
    public FechaOrden?: string;
    public StatusOrden?: string;    
    public EmailPayer?: string;
    public CiudadCodePayer?: string;
    public NombrePayer?: string;
    public IdMerchant?: string;
    public StatusCaptura?: string;
    public IdCaptura?: string;
    
    public FormaPago?: string;
    public MetodoPago?: string;
    public LugarPago?: string;
    public LugarReserva?: string;
}


export class respPagoPaypal {
    
    public ok?: boolean;
    public respuesta?: string;
    
    public idOrden?: string;
    public fechaOrden?: string;
    public statusOrden?: string;
    public emailPayer?: string;
    public ciudadCodePayer?: string;
    public nombrePayer?: string;
    public idMerchant?: string;
    public statusCaptura?: string;
    public idCaptura?: string;
    
}


export interface getReservacion {
    ok?: boolean;
    reserva?: generarReserva;
    message?: string;
}

export class correoContacto {
    Nombre?: string = '';
    Correo?: string = '';
    Telefono?: string = '';
    Mensaje?: string = '';
}