import { NoticeOfPrivacyComponent } from './pages/notice-of-privacy/notice-of-privacy.component';
import { MyReservationComponent } from './pages/my-reservation/my-reservation.component';
import { PaqueteInfoComponent } from './pages/paquete-info/paquete-info.component';
import { TourDetailComponent } from './pages/tour-detail/tour-detail.component';
import { TourInfoComponent } from './pages/tour-info/tour-info.component';
import { ReservationDetailComponent } from './pages/reservation-detail/reservation-detail.component';
import { ToursComponent } from './pages/tours/tours.component';
import { PackageComponent } from './pages/package/package.component';
import { ContactComponent } from './pages/contact/contact.component';
import { AboutComponent } from './pages/about/about.component';
import { HomeComponent } from './pages/home/home.component';
import { PageComponent } from './layouts/page/page.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: "",
    component: PageComponent,
    children: [
      { path: "home", component: HomeComponent },
      { path: "about", component: AboutComponent },
      { path: "contact", component: ContactComponent },
      { path: "package", component: PackageComponent },
      { path: "tours", component: ToursComponent },
      { path: "destination-info/:id", component: TourInfoComponent },
      { path: "destination-package-info/:id", component: PaqueteInfoComponent },
      { path: "destination-reservation", component: TourDetailComponent },
      { path: "reservation-detail", component: ReservationDetailComponent },
      { path: "my-reservation", component: MyReservationComponent },
      { path: "privacy-notice", component: NoticeOfPrivacyComponent },
      { path: "**", redirectTo: "home", pathMatch: "full" },
      { path: "", redirectTo: "home", pathMatch: "full" }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
