<hr style="margin-top:25px;margin-bottom:5px;">
<h5 style="font-size: 12px;color:#ffffff;font-weight: bold;text-align: center;font-family: Champagne" > 
    {{ txtDESCODE }} 
</h5>

<div class="input-group" *ngIf="!cupon_valido" >
    <input type="text" class="form-control" [(ngModel)]="_cupon.Codigo" [placeholder]="txtIPTCODE" style="background: #f9d08d;border-color: #f9d08d;">
    <div class="input-group-prepend" style="border-radius: 0 !important;">
      <button (click)="aplicarCupon()" type="button" style="border-radius: 0 !important;background: #f5a31f;border-color: #f5a31f;font-family: Champagne" class="btn btn-secondary"> {{ txtBTNCODE }} </button>
    </div>
</div>
<p style="margin-top:13px;font-family: Champagne" class="resp-incorrecto" *ngIf="viewError" >*  {{ txtCODERRO }} </p>

<p *ngIf="cupon_valido" style="margin-top:16px;text-transform: uppercase;" class="resp-correcto">
    * {{ txtCOD1COR }} 
      <span style="color: #ffffff;"> {{ _cupon.Codigo }}. </span>   
      {{ txtCOD2COR }}
</p>



            