<div class="container">
    <br>
    <h2 style="font-size: 25px;color: #fff;font-weight: 700;line-height: 30px;text-align: center;"> {{ title }} </h2>
    <h2 style="font-size: 17px;color: #fff;text-align: center;">{{ titleSub }}</h2>
    <br>
        <div class="row">

            <div *ngFor="let t of dataTours" class="card col-6 col-md-3 padding-card">
                <div class="subcard">
                    <img [src]="getImagen(t.img)" class="card-img-top" >
                    <div class="card-body">
                        <h5 class="card-title">{{ t.title }}</h5>
                        <p class="card-text">{{ t.text }}.</p>
                        <span class="badge bg-danger" *ngIf="showPrice.Mostrar_Precio_Tours=='SI'" > ${{t.Costo | number : '1.2-2'}} {{ moneda }} </span>
                        <a class="btn btn-warning" [routerLink]="['/destination-info',t.Id]" style="background: transparent; border-color: #f5a31f !important;color: #f5a31f"> {{ titleBtn }} </a>
                    </div>
                </div>
            </div>

        </div>
    <br>
</div>
