import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LocalDataServiceService } from '../../services/local-data-service.service';

const _idioma = environment.idioma;

@Component({
  selector: 'app-notice-of-privacy',
  templateUrl: './notice-of-privacy.component.html',
  styleUrls: ['./notice-of-privacy.component.css']
})
export class NoticeOfPrivacyComponent implements OnInit {

  public idioma: string;
  constructor( private localData: LocalDataServiceService ) {
    this.idioma = _idioma || 'en';
  }

  ngOnInit(): void {
    this.subirScroll();
  }


  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 2000);
  }

}
