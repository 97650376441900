function getLang() {
  if( localStorage.getItem('idioma') ) {
    return localStorage.getItem('idioma');
  } else {

    if( window.navigator.language === 'es-ES' ) {
      return 'es';
    } else {
      return 'en';
    }
  }
}

export const environment = {
  production: true,

  idioma: getLang(),

  urlrest: 'https://eitur.mx/backend-eitur',
  //urlrest: 'http://localhost/backend-eitur',


  // --- KEY PAYPAL --- //
  //Prueba:
  //keyPaypal:'ASBeNRsz4MY3FUfz-iUYgzfW8cxqFkJ1jEOOSGm3aIIFd4zvK-yjx1uj2mDXNqqclyRZzi4Kc_SSp0-G',

  //Real:
  keyPaypal: 'AW3IPP0atK5VC-Wry3CttdkEPgNz33H-G7lV_9Jjy12Up2XhiqZnNLWixxlXu8wGrU0LXKcDkYF235ZX',



  // --- KEY OPEN PAY --- //
  //Prueba:
  idOpenPay: 'mqxwwbnyebgv1mqysl7r',
  keyOpenPay_Publica: 'pk_f76f52c46fbc4d3aa5243b57e4467e9c',
  keyOpenPay_Private: 'sk_149b597cbf1b4b69bbdcdb2fc853edad',
  sandBoxOpenPay: 'https://sandbox-api.openpay.mx/v1/'

  //Real:
  //keyOpenPay: ''


};
