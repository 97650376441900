import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-page',
  templateUrl: './loading-page.component.html',
  styleUrls: ['./loading-page.component.css']
})
export class LoadingPageComponent implements OnInit {

  lang_loading: string;

  constructor() {
    this.lang_loading = localStorage.getItem('idioma') || 'es';
  }

  ngOnInit(): void {
  }

}
