<br>
<br>
<br>
<div class="container-xl contenido-principal">

    <br>
    <div style="text-align: center;">
      <h5 style="text-align: center;" class="titleDescrip" > {{ txtForm.TITLERES }} </h5>
      <button type="button" class="btn btn-danger" *ngIf="resultadoCorrecto" (click)="exportarPdf()">
        <i class="material-icons" >picture_as_pdf</i>
      </button>
    </div>



    <!-- START: Todo esto se muestra para filtrar una reserva -->
    <div *ngIf="!resultadoCorrecto" >
      <p class="subdescrip" style="text-align: justify;width: 90%;margin-left: auto;margin-right: auto;">
        {{ txtForm.SUBTIRES }}
      </p>
      <div class="card mb-12" >
          <div class="row g-0">
            <div class="col-md-6" style="text-align: center;margin-bottom: 1%;">
              <img src="assets/img/reserva-imgs.png" style="width: 80%;text-align: center;">
            </div>
            <div class="col-md-6">
              <div class="card-body">
                <h5 class="card-title" style="text-align: center;" > {{ txtForm.TITLEFOR }} </h5>

                <div *ngIf="mostrarAlerta" class="alert alert-danger alert-dismissible fade show" role="alert" style="font-family: Champagne;font-size: 13px;">
                  <strong style="font-weight: bold;margin-right: 5px;" >ERROR!</strong> {{ txtForm.ERROR }}
                </div>

                  <label for="inputPassword4" class="form-label">{{ txtForm.IPTID }}:</label>
                  <input [(ngModel)]="_creserva_.Id" type="number" class="form-control" [placeholder]="txtForm.IPTID">
                  <p class="txt-incorrecto" *ngIf="_creserva_.Id == null" > {{ txtForm.REQUIERE }} </p>

                  <br>
                  <label for="inputPassword4" class="form-label">{{ txtForm.IPTCORRE }}:</label>
                  <input [(ngModel)]="_creserva_.Correo" type="email" class="form-control" [placeholder]="txtForm.IPTCORRE">
                  <p class="txt-incorrecto" *ngIf="_creserva_.Correo == ''" > {{ txtForm.REQUIERE }} </p>

                  <br>
                  <label for="inputPassword4" class="form-label">{{ txtForm.IPTTELEF }}:</label>
                  <input [(ngModel)]="_creserva_.Telefono" type="number" class="form-control" [placeholder]="txtForm.IPTTELEF">
                  <p class="txt-incorrecto" *ngIf="_creserva_.Telefono == null || _creserva_.Telefono == ''" > {{ txtForm.REQUIERE }} </p>

                  <br>
                  <button (click)="obtenerDatosReservacion()" [disabled]="_creserva_.Id===null || _creserva_.Correo === '' || _creserva_.Telefono ==='' || _creserva_.Telefono === null" type="submit" class="btn button-reserva" style="width: 100%;" > {{ txtForm.BTNFORM }} </button>


              </div>
            </div>
          </div>
      </div>
    </div>
    <!-- END: Todo esto se muestra para filtrar una reserva -->

    <!-- START: Todo esto se muestra cuando se realizó y existe la reserva -->
    <div>
      <br>
      <div class="card mb-12" >
        <div class="card-body row" *ngIf="resultadoCorrecto">

          <div class="col-12" style="text-align: center;">
            <p style="color: #00659f;text-align: center;" *ngIf="dataReservacion.Tipo_Reserva==='TOUR'">{{ txtForm.TOUR }}</p>
            <p style="color: #00659f;text-align: center;" *ngIf="dataReservacion.Tipo_Reserva==='PAQUETE'">{{ txtForm.PAQUETE }}</p>
            <p style="color: #00659f;text-align: center;" *ngIf="dataReservacion.Tipo_Reserva==='TRANSFER'">{{ txtForm.TRANSFER }}</p>
          </div>
          <hr>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.FOLIO }}</p>
            <p class="label-descrip" > {{ dataReservacion.Id }} </p>
          </div>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.STATUS }}</p>
            <p class="label-descrip-badge" style="background: green;" *ngIf="dataReservacion.Status_Txt=='VIGENTE'" > {{ txtForm.VIGENTE }} </p>
            <p class="label-descrip-badge" style="background: red;" *ngIf="dataReservacion.Status_Txt=='CANCELADO'" > {{ txtForm.CANCELADO }} </p>
            <p class="label-descrip-badge" style="background: orange ;" *ngIf="dataReservacion.Status_Txt=='VENCIDO'" > {{ txtForm.VENCIDO }} </p>
          </div>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.FECHAR }}</p>
            <p class="label-descrip" > {{ dataReservacion.Fecha }} </p>
          </div>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.HORAR }}</p>
            <p class="label-descrip" >{{ dataReservacion.Hora }}</p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.TRASLADO }}</p>
            <p class="label-descrip" *ngIf="dataReservacion.Traslado=='RT'"> {{ txtForm.VRTRASLADO }} </p>
            <p class="label-descrip" *ngIf="dataReservacion.Traslado=='OW'"> {{ txtForm.VSTRASLADO }} </p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.DESDE }}</p>
            <p class="label-descrip" > {{ dataReservacion.Desde_Txt }} </p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.HASTA }}</p>
            <p class="label-descrip" > {{ dataReservacion.Hasta_Txt }} </p>
          </div>

          <div class="col-md-3" *ngIf="dataReservacion.TrasladoInterno=='RTAH' || dataReservacion.TrasladoInterno=='RTHH'">
            <p class="label-title" >{{ txtForm.LLEGADA }}</p>
            <p class="label-descrip" > {{ dataReservacion.Llegada }} </p>
          </div>

          <div class="col-md-3" *ngIf="dataReservacion.TrasladoInterno=='RTAH' || dataReservacion.TrasladoInterno=='RTHH'">
            <p class="label-title" >{{ txtForm.SALIDA }}</p>
            <p class="label-descrip" > {{ dataReservacion.Salida }} </p>
          </div>

          <div class="col-md-6" *ngIf="dataReservacion.TrasladoInterno=='RTTP' || dataReservacion.TrasladoInterno=='OWHH' || dataReservacion.TrasladoInterno=='OWHA' || dataReservacion.TrasladoInterno=='OWAH'">
            <p class="label-title" >{{ txtForm.FECHARESERVA }}</p>
            <p class="label-descrip" > {{ dataReservacion.Llegada || dataReservacion.Salida }} </p>
          </div>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.ADULTO }}</p>
            <p class="label-descrip" > {{ dataReservacion.Adultos }} </p>
          </div>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.NINO }}</p>
            <p class="label-descrip" > {{ dataReservacion.Ninos }} </p>
          </div>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.PAIS }}</p>
            <p class="label-descrip" > {{ dataReservacion.Pais_Pasajero | uppercase }} </p>
          </div>

          <div class="col-md-2" >
            <p class="label-title" >{{ txtForm.CUARTO }}</p>
            <p class="label-descrip" > {{ dataReservacion.Numero_Cuarto }} </p>
          </div>

          <div class="col-md-7" >
            <p class="label-title" >{{ txtForm.COMENTARIO }}</p>
            <p class="label-descrip" > {{ dataReservacion.Comentarios_Reserva || '----------' }} </p>
          </div>

          <hr>
          <div class="col-12" >
            <p style="color: #00659f;text-align: center;" >{{ txtForm.DPASAJERO }}</p>
          </div>
          <hr>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.NOMBREP }}</p>
            <p class="label-descrip" > {{ dataReservacion.Nombre_Pasajero }} </p>
          </div>

          <div class="col-md-3" >
            <p class="label-title" >{{ txtForm.APELLIP }}</p>
            <p class="label-descrip"> {{ dataReservacion.Apellido_Pasajero }} </p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.EMAILP }}</p>
            <p class="label-descrip" > {{ dataReservacion.Email_Pasajero }} </p>
          </div>

          <div class="col-md-2" >
            <p class="label-title" >{{ txtForm.TELEFP }}</p>
            <p class="label-descrip" > {{ dataReservacion.Telefono_Pasajero }} </p>
          </div>


          <hr *ngIf="dataReservacion.Tipo_Reserva==='TRANSFER'&&(dataReservacion.TrasladoInterno=='RTAH'||dataReservacion.TrasladoInterno=='OWAH'||dataReservacion.TrasladoInterno=='OWHA')">

          <div class="col-12" *ngIf="dataReservacion.Tipo_Reserva==='TRANSFER'&&(dataReservacion.TrasladoInterno=='RTAH'||
          dataReservacion.TrasladoInterno=='OWAH'||dataReservacion.TrasladoInterno=='OWHA')">

          <p style="color: #00659f;text-align: center;" >{{ txtForm.DVUELO }}</p>
          </div>

          <hr *ngIf="dataReservacion.Tipo_Reserva==='TRANSFER'&&(dataReservacion.TrasladoInterno=='RTAH'||dataReservacion.TrasladoInterno=='OWAH'||dataReservacion.TrasladoInterno=='OWHA')">

            <div class="col-md-4" *ngIf="dataReservacion.TrasladoInterno==='RTAH'||dataReservacion.TrasladoInterno==='OWAH'">
              <p class="label-title" >{{ txtForm.AEROLILLE }}</p>
              <p class="label-descrip" > {{ dataReservacion.Aerolina_Llegada || '----------' }} </p>
            </div>

            <div class="col-md-4" *ngIf="dataReservacion.TrasladoInterno==='RTAH'||dataReservacion.TrasladoInterno==='OWAH'">
              <p class="label-title" >{{ txtForm.CODVUELLE }}</p>
              <p class="label-descrip" > {{ dataReservacion.Codigo_Vuelo_LLegada || '----------' }} </p>
            </div>

            <div class="col-md-4" *ngIf="dataReservacion.TrasladoInterno==='RTAH'||dataReservacion.TrasladoInterno==='OWAH'">
              <p class="label-title" >{{ txtForm.HRVUELLE }}</p>
              <p class="label-descrip" > {{ dataReservacion.Hora_Vuelo_LLegada || '----------'}} </p>
            </div>


            <div class="col-md-4" *ngIf="dataReservacion.TrasladoInterno==='RTAH'||dataReservacion.TrasladoInterno==='OWHA'">
              <p class="label-title" >{{ txtForm.AEROLILSAL }}</p>
              <p class="label-descrip" > {{ dataReservacion.Aerolina_Salida || '----------' }} </p>
            </div>

            <div class="col-md-4" *ngIf="dataReservacion.TrasladoInterno==='RTAH'||dataReservacion.TrasladoInterno==='OWHA'">
              <p class="label-title" >{{ txtForm.CODVUESAL }}</p>
              <p class="label-descrip" > {{ dataReservacion.Codigo_Vuelo_Salida || '----------' }} </p>
            </div>

            <div class="col-md-4" *ngIf="dataReservacion.TrasladoInterno==='RTAH'||dataReservacion.TrasladoInterno==='OWHA'">
              <p class="label-title" >{{ txtForm.HRVUESAL }}</p>
              <p class="label-descrip" > {{ dataReservacion.Hora_Vuelo_Salida || '----------' }} </p>
            </div>

            <div class="col-md-4" *ngIf="dataReservacion.TrasladoInterno==='RTAH'||dataReservacion.TrasladoInterno==='OWAH'">
              <p class="label-title" >{{ txtForm.DOSTERMINAL }}</p>
              <p class="label-descrip" > {{ dataReservacion.LLegada_Dos_Terminales || '----------' }} </p>
            </div>







          <hr *ngIf="(dataReservacion.TrasladoInterno=='RTHH'||dataReservacion.TrasladoInterno=='OWHH'||dataReservacion.TrasladoInterno=='RTTP')">
          <div class="col-12" *ngIf="(dataReservacion.TrasladoInterno=='RTHH'||dataReservacion.TrasladoInterno=='OWHH'||dataReservacion.TrasladoInterno=='RTTP')">
            <p style="color: #00659f;text-align: center;" >{{ txtForm.HORASERVICIO }}</p>
          </div>
          <hr *ngIf="(dataReservacion.TrasladoInterno=='RTHH'||dataReservacion.TrasladoInterno=='OWHH')">

          <div class="col-md-6" *ngIf="dataReservacion.TrasladoInterno==='RTHH'||dataReservacion.TrasladoInterno==='OWHH'||dataReservacion.TrasladoInterno=='RTTP'">
            <p class="label-title" >{{ txtForm.HTLRECEP }}</p>
            <p class="label-descrip" > {{ dataReservacion.Desde_Txt || '----------' }} </p>
          </div>

          <div class="col-md-6" *ngIf="dataReservacion.TrasladoInterno==='RTHH'||dataReservacion.TrasladoInterno==='OWHH'||dataReservacion.TrasladoInterno=='RTTP'">
            <p class="label-title" >{{ txtForm.HRRECEP }}</p>
            <p class="label-descrip" > {{ dataReservacion.Hora_Vuelo_LLegada || dataReservacion.Hora_Vuelo_Salida }} </p>
          </div>

          <div class="col-md-6" *ngIf="dataReservacion.TrasladoInterno==='RTHH'||dataReservacion.TrasladoInterno=='RTTP'">
            <p class="label-title" >{{ txtForm.HTLDEST }}</p>
            <p class="label-descrip" > {{ dataReservacion.Hasta_Txt || '----------' }} </p>
          </div>

          <div class="col-md-6" *ngIf="dataReservacion.TrasladoInterno==='RTHH'||dataReservacion.TrasladoInterno=='RTTP'">
            <p class="label-title" >{{ txtForm.HRDEST }}</p>
            <p class="label-descrip" > {{ dataReservacion.Hora_Vuelo_Salida || dataReservacion.Hora_Vuelo_LLegada }} </p>
          </div>








          <hr>
          <div class="col-12" >
            <p style="color: #00659f;text-align: center;" >{{ txtForm.DFACTURA }}</p>
          </div>
          <hr>

          <div class="col-md-6" >
            <p class="label-title" >{{ txtForm.NOMBREF }}</p>
            <p class="label-descrip" > {{ dataReservacion.Nombre_Factura || '----------' }} </p>
          </div>

          <div class="col-md-6" >
            <p class="label-title" >{{ txtForm.DIRECCF }}</p>
            <p class="label-descrip" > {{ dataReservacion.Direccion_Factura || '----------' }} </p>
          </div>


          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.RFCF }}</p>
            <p class="label-descrip" > {{ dataReservacion.Rfc_Factura || '----------' }} </p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.CORREOF }}</p>
            <p class="label-descrip" > {{ dataReservacion.Correo_Factura || '----------' }} </p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.CFDIF }}</p>
            <p class="label-descrip" > {{ dataReservacion.Cfdi_Factura || '----------' }} </p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.CIUDADF }}</p>
            <p class="label-descrip" > {{ dataReservacion.Ciudad_Factura || '----------' }} </p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.ESTADOF }}</p>
            <p class="label-descrip" > {{ dataReservacion.Estado_Factura || '----------' }} </p>
          </div>

          <div class="col-md-4" >
            <p class="label-title" >{{ txtForm.CODIGOF }}</p>
            <p class="label-descrip" > {{ dataReservacion.Codigo_Postal || '----------' }} </p>
          </div>

          <hr>
          <div class="col-12" >
            <p style="color: #00659f;text-align: center;" >{{ txtForm.DTOTALES }}</p>
          </div>
          <hr>

          <div class="col-md-4"  style="text-align: center;">
            <p class="label-title" >{{ txtForm.TCOSTO }}</p>
            <p class="label-descrip" style="font-size: 18px !important;" *ngIf="dataReservacion.Costo_Total>=1"> ${{ dataReservacion.Costo }} {{ dataReservacion.Tipo_Moneda }} </p>
            <p class="label-descrip" style="font-size: 18px !important;" *ngIf="dataReservacion.Costo_Total<1"> {{ txtForm.PORDEFINIR }} </p>
          </div>

          <div class="col-md-4"  style="text-align: center;">
            <p class="label-title" >{{ txtForm.TCUPON }}</p>
            <p class="label-descrip" style="font-size: 18px !important;" > {{ dataReservacion.Codigo_Descuento }} </p>
          </div>

          <div class="col-md-4"  style="text-align: center;">
            <p class="label-title" >{{ txtForm.TDESCUENTO }}</p>
            <p class="label-descrip" style="font-size: 18px !important;" > -{{ dataReservacion.Costo_Descuento }}% </p>
          </div>

          <div class="col-md-6"  style="text-align: center;">
            <p class="label-title" >{{ txtForm.TOTAL }}</p>
            <p class="label-descrip" style="font-size: 18px !important;" *ngIf="dataReservacion.Costo_Total>=1"> ${{ dataReservacion.Costo_Total }} {{ dataReservacion.Tipo_Moneda }} </p>
            <p class="label-descrip" style="font-size: 18px !important;" *ngIf="dataReservacion.Costo_Total<1"> {{ txtForm.PORDEFINIR }} </p>
          </div>

          <div class="col-md-6"  style="text-align: center;">
            <p class="label-title" >{{ txtForm.STATUSPAGO }}</p>
            <p class="label-descrip-badge" style="background: green;" *ngIf="dataReservacion.StatusCaptura=='COMPLETED'"> {{ txtForm.PAGOPAGADO }} </p>
            <p class="label-descrip-badge" style="background: red;" *ngIf="dataReservacion.StatusCaptura=='PENDIENTE'"> {{ txtForm.PAGOPENDI }} </p>
            <p class="label-descrip-badge" style="background: orange;" *ngIf="dataReservacion.StatusCaptura=='DESCONOCIDO'"> {{ txtForm.PAGODESCO }} </p>
          </div>


        </div>
      </div>

    </div>
    <!-- END: Todo esto se muestra cuando se realizó y existe la reserva -->


</div>
<br>
<app-footer></app-footer>
