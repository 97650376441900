import { Component, OnInit } from '@angular/core';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  public txtContacto: any;

  constructor( private localData: LocalDataServiceService ) {
    this.txtContacto = [];
  }

  ngOnInit(): void {
    this.localData.isLoading = true;
    this.localData.getDataContact().subscribe( data => {
        this.txtContacto = data;
    });
    this.subirScroll();
  }

  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,5);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    }, 2000);
  }

}
