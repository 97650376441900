<div class="contenido-form">
    <h5 style="margin-bottom: .8em;text-transform: uppercase;"> {{ dataPaqueteTour.title }} </h5>
    <div class="formulario row" >
        <div class="col-12 col-md-3">
            <label for="inputAddress2" class="form-label label-form"> {{ dataTxtInfo.FECHA }} </label>
            <input (change)="actualizarFecha($event)" [(ngModel)]="formReservacion.Fecha" type="date" [min]="getFechaMinima" class="form-control">
            <p class="campo-requerido" *ngIf="formReservacion.Fecha===''" > {{ dataTxtInfo.FECHAR }} </p>
        </div>
        <div class="col-12 col-md-3">
            <label for="inputAddress2" class="form-label label-form"> {{ dataTxtInfo.ADULTO }} </label>
            <select class="form-control" [(ngModel)]="formReservacion.Adultos">
                <option [selected]="i==1" *ngFor="let i of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [value]="i"> {{i}} </option>
            </select>
        </div>
        <div class="col-12 col-md-3">
            <label for="inputAddress2" class="form-label label-form"> {{ dataTxtInfo.NINO }} </label>
            <select class="form-control" [(ngModel)]="formReservacion.Ninos">
              <option [selected]="i==0" *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]" [value]="i"> {{i}} </option>
            </select>
          </div>
          <div class="col-12 col-md-3">
            <label *ngIf="showPrice.Mostrar_Precio_Tours=='SI'" for="inputAddress2" class="form-label label-price" > ${{ calculatePrice() | number : '1.2-2' }} {{ dataTxtInfo.MONEDA }} </label>
            <div *ngIf="showPrice.Mostrar_Precio_Tours=='NO'" style="height: 32px;" ></div>
            <button (click)="enviarFormulario()" [disabled]="formReservacion.Fecha===''" type="submit" class="btn " style="width: 100%;margin-top: 0px;" > {{ dataTxtInfo.BTN }} </button>
        </div>
    </div>

</div>

<div class="container-xl" style="margin-top: 2em;" >

    <div class="row" style="width: 86%;margin-left: auto;margin-right: auto;">

        <div class="col-12 col-md-6">
            <img *ngIf="dataPaqueteTour.img" class="img-tour" [src]="getImagen(dataPaqueteTour.img)">

            <app-comparacion-servicio [nameTour]="dataPaqueteTour.title" ></app-comparacion-servicio>

            <p style="font-size:15px;text-align: center;font-family: Champagne;" >
                {{ dataTxtInfo.MAYINF }} <span [routerLink]="['/contact']" style="color:#00659f;text-decoration: underline;cursor: pointer;">{{ dataTxtInfo.CONTAC }}</span>
            </p>
        </div>

        <div class="col-12 col-md-6">

            <p *ngIf="showPrice.Mostrar_Precio_Tours=='SI'" class="titulo-subcontent"> {{ dataTxtInfo.TRAP }} </p>
            <p *ngIf="showPrice.Mostrar_Precio_Tours=='SI'" class="titulo-precio"> ${{ dataPaqueteTour.Costo | number : '1.2-2'}} {{ dataTxtInfo.MONEDA }} </p>
            <p *ngIf="showPrice.Mostrar_Precio_Tours=='SI'" class="titulo-simple"> {{ dataTxtInfo.TRAPC }} </p>

            <p class="titulo-subcontent"> {{ dataTxtInfo.TRAI }} </p>

            <p *ngFor="let t of dataTransportePaqueteTour" style="font-family: Champagne;margin-bottom: 0px;font-size: 14px;"> <i class="material-icons" style="font-size: 14px;color: #00659f;"> airport_shuttle </i>
                {{ t.Descripcion }}
            </p>

            <p class="titulo-subcontent" style="margin-top: 10px;"> {{ dataTxtInfo.DESCRIP }} </p>
            <p *ngFor="let td of dataDescripcionPaqueteTour" style="font-family: Champagne;font-size: 14px;text-align: justify;margin-bottom: 0;">
                {{ td.Descripcion }}
            </p>
            <p style="font-family: Champagne;font-size: 14px;text-align: justify;margin-top: 0;" *ngFor="let d of dataDetallePaqueteTour" >
                {{ d.Descripcion }}
            </p>

            <p class="titulo-subcontent" style="margin-top: 10px;"> {{ dataPaqueteTour.title }} <span>{{ dataTxtInfo.TOUI }}</span>  </p>

            <p *ngFor="let i of dataIncluyePaqueteTour" style="font-family: Champagne;margin-bottom: 0px;font-size: 14px;"> <i class="material-icons" style="font-size: 14px;color: #00659f;"> tour </i>
                {{ i.Descripcion }}
            </p>


        </div>

    </div>

    <hr>

</div>

<app-footer></app-footer>
