import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { correoContacto } from './../../../models/reservacion.model';
import { Component, OnInit } from '@angular/core';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  txtFooter: any;
  _mcorreo_: correoContacto;
  enviandoMensaje: boolean = false;
  correctoMensaje: boolean = false;
  errorMensaje: boolean = false;

  constructor( private localData: LocalDataServiceService,
               private externalData: ExternalDataServiceService ) { 
    this.txtFooter = [];
    this._mcorreo_ = new correoContacto();
  }

  ngOnInit(): void {
    this.localData.getFooterReserva().subscribe( data => {
      this.txtFooter = data;
    });
  }

  sendCorreo() {

    this.enviandoMensaje = true;

    this.externalData.postSendCorreoContacto( this._mcorreo_ ).subscribe( (data: any) => {
      
      if( data.ok ) {
        this.mostrarMensajeCorrecto();
      } else {
        this.mostrarMensajeError();
      }

      this.enviandoMensaje = false;

    });
  }


  mostrarMensajeCorrecto() {
    this.correctoMensaje = true;
    this._mcorreo_ = new correoContacto();
    setTimeout(() => {
      this.correctoMensaje = false;
    }, 3000);
  }

  mostrarMensajeError() {
    this.errorMensaje = true;
    setTimeout(() => {
      this.errorMensaje = false;
    }, 3000);
  }

}
