import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-carousel-comentarios',
  templateUrl: './carousel-comentarios.component.html',
  styleUrls: ['./carousel-comentarios.component.scss']
})
export class CarouselComentariosComponent implements OnInit {

  public comentarios: any;
  constructor( private externalData: ExternalDataServiceService ) { 
    this.comentarios  = [];
    
  }

  ngOnInit(): void {
    this.externalData.getComentariosHome().subscribe( (data: any) => {
      this.comentarios= data.comentarios;
    });
  }

}
