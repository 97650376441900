import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Reservacion } from 'src/app/models/reservacion.model';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';

@Component({
  selector: 'app-reserva-principal',
  templateUrl: './reserva-principal.component.html',
  styleUrls: ['./reserva-principal.component.css']
})
export class ReservaPrincipalComponent implements OnInit {

  /* TEXTO PARA EL FORMULARIO */
  public TRA1: any;
  public TRA2: any;
  public TRA3: any;
  public TRA4: any;
  public TRA5: any;
  public txtForm: any;

  /* Modelo para formulario */
  public _formreserva_: Reservacion;

  /* Tipo de traslado interno */
  tipoDeTraslado: string = 'RTAH';

  /* FECHAS */
  _fechaActual: string = this.localData.getFechaActual();
  _fechaMinima: string = this.localData.getFechaMinima();
  _fechaMinimaLlegada: string = this.localData.getFechaMinima();

  //Fecha minima para la fecha salida 
  _fechaMinimaSalida: string = this.localData.getFechaMinima();

  constructor( private localData: LocalDataServiceService,
               private router: Router  ) {
    
    this.txtForm = [];
    this._formreserva_ = new Reservacion();
    this.TRA1 = [];
    this.TRA2 = [];
    this.TRA3 = [];
    this.TRA4 = [];
    this.TRA5 = [];
  }
  
  ngOnInit(): void {
    this.getTxtForm();
  }

  //Obtener los textos ingles/español
  getTxtForm() {

    this.localData.getTxtFormReserva().subscribe( (data: any) => {
      this.txtForm = data;
      this.TRA1 = data.TRA1;
      this.TRA2 = data.TRA2;
      this.TRA3 = data.TRA3;
      this.TRA4 = data.TRA4;
      this.TRA5 = data.TRA5;
    });

  }

  asignarTraslado( value: any ) {

    this.tipoDeTraslado = value.target.value;
    this._formreserva_.TrasladoInterno = this.tipoDeTraslado;

    
    this._formreserva_.Llegada = '';
    this._formreserva_.Salida  = '';
    

    if( this.tipoDeTraslado == 'RTAH' ) {
    
      this._formreserva_.Traslado = 'RT';

      this._formreserva_.Desde = 1;
      this._formreserva_.Desde_Txt = 'CANCUN AIRPORT/AEROPUERTO';
      this._formreserva_.Hasta = null || 0;
    
    } else if( this.tipoDeTraslado == 'RTHH' ) {
     
      this._formreserva_.Traslado = 'RT';

      this._formreserva_.Desde = null || 0;
      this._formreserva_.Hasta = null || 0;

    } else if( this.tipoDeTraslado == 'OWAH' ) {
      
      this._formreserva_.Traslado = 'OW';
      this._formreserva_.Desde = 1;
      this._formreserva_.Desde_Txt = 'CANCUN AIRPORT/AEROPUERTO';
      this._formreserva_.Hasta = null || 0;

    } else if( this.tipoDeTraslado == 'OWHA' ) {
    
      this._formreserva_.Traslado = 'OW';
      
      this._formreserva_.Desde = null || 0;
      this._formreserva_.Hasta = 1;
      this._formreserva_.Hasta_Txt = 'CANCUN AIRPORT/AEROPUERTO';


    } else if( this.tipoDeTraslado == 'OWHH' ) {
      
      this._formreserva_.Traslado = 'OW';
      
      this._formreserva_.Desde = null || 0;
      this._formreserva_.Hasta = null || 0;

    }


  }

  //Valor seleccionado del SELECT del destino desde
  getDataDesde( event: any ) {
    if( !event ) {
      return
    }
    this._formreserva_.Desde = event;
  }
  
  //Valor seleccionado del SELECT del destino hasta
  getDataHasta( event: any ) {
    if( !event ) {
      return
    }
    this._formreserva_.Hasta = event;
  }

  //Actualizar el valor de fecha salida
  changeFechaSalida() {
    
    this.validaFechaLlegada( this._formreserva_.Llegada );

    if ( this.tipoDeTraslado === 'RTAH' || this.tipoDeTraslado === 'RTHH' ) {

      this._fechaMinimaSalida = this._formreserva_.Llegada!;
      this._formreserva_.Salida = this._formreserva_.Llegada!;

    }

  
  }

  //Enviar el formulario
  enviarFormulario() {
    this._formreserva_.Idioma = this.localData.idiomaActual;
    this._formreserva_.TrasladoInterno = this.tipoDeTraslado;
    this.localData.prospectoReserva = this._formreserva_;
    this.router.navigate(['/reservation-detail']);
  }

  //Validar si los resultados son correctos
  validarCampos(): boolean {

    if( this._formreserva_.Desde == 0 || this._formreserva_.Desde === null || this._formreserva_.Hasta == 0 || 
      this._formreserva_.Hasta === null || ( this._formreserva_.Desde == this._formreserva_.Hasta ) ) {
      //console.log( 'Campos vacios en los lugares' );
      return false;
    } 
    
    else if( ( this.tipoDeTraslado == 'RTAH' || this.tipoDeTraslado == 'RTHH' ) && 
             (this._formreserva_.Llegada == '' || this._formreserva_.Salida == '') ) {
      //console.log( 'Fecha incorrecta para RTAH-RTHH' );
      return false;
    } 
    
    else if( this.tipoDeTraslado == 'OWAH'  && this._formreserva_.Llegada == '' ) {
        //console.log( 'Fecha incorrecta para OWAH-OWHH-OWHA' );
      return false;
    }
    
    else if( (this.tipoDeTraslado == 'OWHA' || this.tipoDeTraslado == 'OWHH' ) && 
             this._formreserva_.Salida == '' ) {
      //console.log( 'Fecha incorrecta para OWAH-OWHH-OWHA' );
      return false;
    } 

    else {

      return true;
    
    }

  }

  cambiarValorDesdeTxt( valor: any ) {
    this._formreserva_.Desde_Txt = valor;
  }

  cambiarValorHastaTxt( valor: any ) {
    this._formreserva_.Hasta_Txt = valor;
  }


  validaFechaLlegada( fecha: any) {
    if( fecha <= this._fechaActual ) {
      this._formreserva_.Llegada = this._fechaMinima;
    }
  }

  validaFechaSalida( valor: any ) {
    const fecha = valor.target.value;
    if( fecha < this._fechaMinimaSalida ) {
      this._formreserva_.Salida = this._fechaMinimaSalida;
    }
  }

  validaFechaLlegadaServ( valor: any ) {
    const fecha = valor.target.value;
    if( fecha <= this._fechaActual ) {
      this._formreserva_.Llegada = this._fechaMinima;
    }
  }

  validaFechaSalidaServ( valor: any ) {
    const fecha = valor.target.value;
    if( fecha <= this._fechaActual ) {
      this._formreserva_.Salida = this._fechaMinima;
    }
  }

  
 
}
