import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { Destinos } from 'src/app/models/destinos.model';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';

@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: ['./input-search.component.css']
})
export class InputSearchComponent implements OnInit {

  @Output() pushData = new EventEmitter<string>();
  @Output() pushDataTxt = new EventEmitter<string>();
  @Input() placeholder: string = '';
  @Input() habilitado: boolean = false;
  
  datoPorDefault: string;
  
  public keyword = "Nombre";
  public destinos: any;

  
  obDestinos$: Observable<Destinos>;
  
  constructor( private externalData: ExternalDataServiceService ) {
        
    this.obDestinos$ = externalData.getDestinos();           
    this.destinos = [];

    this.datoPorDefault = '';
  
  
  }

  ngOnInit() {
    this.getAllDestinos();
  }

  destinoSeleccionado( event: any ) {
    this.pushData.emit(event.Id);
    this.pushDataTxt.emit(event.Nombre);
  }
  
  inputBorrado( event: any ) {
    this.pushData.emit( '' );
    this.pushDataTxt.emit('');
  }

  getAllDestinos() {
    this.obDestinos$.subscribe( data => {
      this.destinos  =  data.destinos;
    })
  }

}