  <ng-autocomplete 
  [data]="destinos"
  [searchKeyword]="keyword"
  [itemTemplate]="itemTemplate"
  [notFoundTemplate]="notFoundTemplate"
  notFoundText="No existe este destino"
  [placeholder]="placeholder"
  [disabled]="habilitado"
  (selected)='destinoSeleccionado($event)'
  (inputCleared)='inputBorrado( $event )'
  [initialValue]="datoPorDefault"
  >
  </ng-autocomplete>

  <ng-template #itemTemplate let-item>
    <a style="font-size: 11px !important;" [innerHTML]="item.Nombre"></a>
  </ng-template>
    
  <ng-template #notFoundTemplate let-notFound>
    <div [innerHTML]="notFound"></div>
  </ng-template>

