
<div class="container">
    <br>
    <h2 style="font-size: 25px;color: #fff;font-weight: 700;line-height: 30px;text-align: center;"> {{ title }} </h2>
    <br>
        <div class="row">

            <div *ngFor="let t of dataTours" class="card col-6 col-md-3 padding-card">
                <div class="subcard">
                    <img [src]="getImagen(t.img)" class="card-img-top" >
                    <div class="card-body">
                        <h5 class="card-title">{{ t.title }}</h5>
                        <p class="card-text">{{ t.text }}.</p>
                        <span class="badge bg-danger"  *ngIf="showPrice.Mostrar_Precio_Tours=='SI'">
                            <span style="font-size: 14px;" *ngIf="_idioma=='es'"  > ${{t.Costo | number : '1.2-2'}} MXN </span>
                            <span style="font-size: 14px;" *ngIf="_idioma=='en'"  > ${{t.Costo | number : '1.2-2'}} USD </span>
                        </span>
                        <a [routerLink]="['/destination-info',t.Id]" class="btn btn-primary" style="background: transparent; border-color: #00659f;color: #00659f; cursor: pointer;" > {{ titleBtn }} </a>
                    </div>
                </div>
            </div>

        </div>
    <br>
</div>
