import { RouterModule } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {IvyCarouselModule} from 'angular-responsive-carousel';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

/* Importaciones de components */
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footers/footer/footer.component';
import { FooterSecondaryComponent } from './components/footers/footer-secondary/footer-secondary.component';

/* Importacion de layouts */
import { PageComponent } from './layouts/page/page.component';

/* Importaciones de paginas */
import { HomeComponent } from './pages/home/home.component';
import { ToursComponent } from './pages/tours/tours.component';
import { PackageComponent } from './pages/package/package.component';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { HttpClientModule } from '@angular/common/http';
import { ReservaPrincipalComponent } from './components/forms/reserva-principal/reserva-principal.component';
import { CarouselBannerComponent } from './components/carouseles/carousel-banner/carousel-banner.component';
import { ToursHomeComponent } from './components/cards/tours-home/tours-home.component';
import { FacilidadesHomeComponent } from './components/cards/facilidades-home/facilidades-home.component';
import { CarouselComentariosComponent } from './components/carouseles/carousel-comentarios/carousel-comentarios.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { FooterSecondaryFootComponent } from './components/footers/footer-secondary-foot/footer-secondary-foot.component';
import { TxtDescripPackageComponent } from './components/specialText/txt-descrip-package/txt-descrip-package.component';
import { LoadingPageComponent } from './components/loading/loading-page/loading-page.component';
import { RedesSocialesComponent } from './components/buttons/redes-sociales/redes-sociales.component';
import { ReservacionGralComponent } from './components/buttons/reservacion-gral/reservacion-gral.component';
import { ModalReservaComponent } from './components/modals/modal-reserva/modal-reserva.component';
import { ReservacionGralMovilComponent } from './components/buttons/reservacion-gral-movil/reservacion-gral-movil.component';
import { ToursToursComponent } from './components/cards/tours-tours/tours-tours.component';
import { InputSearchComponent } from './components/forms/input-search/input-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { AlertaNoRegistroComponent } from './components/alerta-no-registro/alerta-no-registro.component';
import { ReservationDetailComponent } from './pages/reservation-detail/reservation-detail.component';
import { SinDatosReservacionComponent } from './components/cards/sin-datos-reservacion/sin-datos-reservacion.component';
import { InputCuponReservaComponent } from './components/forms/input-cupon-reserva/input-cupon-reserva.component';
import { BtnPaypalComponent } from './components/buttons/btn-paypal/btn-paypal.component';
import { TourDetailComponent } from './pages/tour-detail/tour-detail.component';
import { TourInfoComponent } from './pages/tour-info/tour-info.component';
import { PaqueteInfoComponent } from './pages/paquete-info/paquete-info.component';
import { MyReservationComponent } from './pages/my-reservation/my-reservation.component';
import { ComparacionServicioComponent } from './components/tables/comparacion-servicio/comparacion-servicio.component';
import { InputSearchDefaultComponent } from './components/forms/input-search-default/input-search-default.component';
import { ChatbootComponent } from './components/chatboot/chatboot.component';
import { NoticeOfPrivacyComponent } from './pages/notice-of-privacy/notice-of-privacy.component';
import { PopupComponent } from './components/modals/popup/popup.component';
import { VideoComponent } from './components/modals/video/video.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FooterComponent,
    FooterSecondaryComponent,
    PageComponent,
    HomeComponent,
    ToursComponent,
    PackageComponent,
    AboutComponent,
    ContactComponent,
    ReservaPrincipalComponent,
    CarouselBannerComponent,
    ToursHomeComponent,
    FacilidadesHomeComponent,
    CarouselComentariosComponent,
    CopyrightComponent,
    FooterSecondaryFootComponent,
    TxtDescripPackageComponent,
    LoadingPageComponent,
    RedesSocialesComponent,
    ReservacionGralComponent,
    ModalReservaComponent,
    ReservacionGralMovilComponent,
    ToursToursComponent,
    InputSearchComponent,
    AlertaNoRegistroComponent,
    ReservationDetailComponent,
    SinDatosReservacionComponent,
    InputCuponReservaComponent,
    BtnPaypalComponent,
    TourDetailComponent,
    TourInfoComponent,
    PaqueteInfoComponent,
    MyReservationComponent,
    ComparacionServicioComponent,
    InputSearchDefaultComponent,
    ChatbootComponent,
    NoticeOfPrivacyComponent,
    PopupComponent,
    VideoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    RouterModule,
    HttpClientModule,
    IvyCarouselModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    AutocompleteLibModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
