import { Component, OnInit } from '@angular/core';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';
import { LocalDataServiceService } from 'src/app/services/local-data-service.service';
import { generarReserva, ReservacionTour, respPagoPaypal } from 'src/app/models/reservacion.model';
import { Router } from '@angular/router';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';


@Component({
  selector: 'app-tour-detail',
  templateUrl: './tour-detail.component.html',
  styleUrls: ['./tour-detail.component.css']
})
export class TourDetailComponent implements OnInit {

  //Textos Ingles/Español para el panel de pago
  public txtForm: any;
  // Variable que sera la reserva principal
  public _reservacion_!: generarReserva;
  //Variable para validar si requiere factura
  public requiereFactura: string = 'NO';
  //Variable del prospecto de reservacion
  public prospectoReserva: ReservacionTour;
  // Inicializar al momento de empezar transacción de pago y cuando los datos sean correctos
  public iniciarPago: boolean =  false;

  public apiUrl: any = this.externalData._apirest;
  // Observable de paises
  paises$!: Observable<any>;
  // Objeto de paises
  paises: any;


  constructor( private externalData: ExternalDataServiceService,
               private localData: LocalDataServiceService,
               private router: Router ) {

      this.prospectoReserva = this.localData.prospectoTourDestino;
      ////console.log(this.prospectoReserva)
      this.txtForm = [];
      this.paises$ = this.externalData.getPaises();
      this.paises = [];
      this._reservacion_ = new generarReserva();
  }

  ngOnInit(): void {

    if(this.localData.prospectoTourDestino === undefined ) {
      this.router.navigate(['/home']);
      return;
    }
    this.obtenerTxtInglesEspañol();
    this.obtenerPaises();
    this._reservacion_.Tipo_Reserva = this.prospectoReserva.Tipo;
    this._reservacion_.Adultos = this.prospectoReserva.Adultos;
    this._reservacion_.Ninos = this.prospectoReserva.Ninos;
    this._reservacion_.Hasta = this.prospectoReserva.Id;
    this._reservacion_.Hasta_Txt = this.prospectoReserva.Txt_Destino?.toUpperCase();
    this._reservacion_.Salida = this.prospectoReserva.Fecha;
    this._reservacion_.Traslado = 'RT';
    this._reservacion_.TrasladoInterno = 'RTTP';
    this._reservacion_.Idioma = this.prospectoReserva.Idioma;
    this._reservacion_.Costo = this.prospectoReserva.Costo;
    this._reservacion_.Costo_Total = this.prospectoReserva.Costo_Total;

    if( this._reservacion_.Idioma === 'es' ) {
      this._reservacion_.Tipo_Moneda = 'MXN';
      this._reservacion_.Traslado_Txt = 'REDONDO';
    } else {
      this._reservacion_.Tipo_Moneda = 'USD';
      this._reservacion_.Traslado_Txt = 'ROUND TRIP';
    }

    this.subirScroll();

  }

  obtenerTxtInglesEspañol() {
    this.localData.getTxtDestinationReservation().subscribe((data: any) => {
      this.txtForm = data;
    });
  }


  // Funcion para validar si el usuario requiere factura
  validarSiRequiereFactura( event: any ) {

    let dato = event.target.value;

    if(dato == 'SI') {
      this.requiereFactura = 'NO';
    }

    if( dato == 'NO' ){
      this.requiereFactura = 'SI';
    }

    this._reservacion_.Nombre_Factura = '';
    this._reservacion_.Direccion_Factura = '';
    this._reservacion_.Ciudad_Factura = '';
    this._reservacion_.Estado_Factura = '';
    this._reservacion_.Codigo_Postal = '';

  }

  cambiarValorDesde( valor: any ) {
    this._reservacion_.Desde = valor;
  }

  cambiarValorDesdeTxt( valor: any ) {
    this._reservacion_.Desde_Txt = valor;
  }

  emailCorrecto(  ): boolean {

    const valor = this._reservacion_.Email_Pasajero;

    let emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    //Se muestra un texto a modo de ejemplo, luego va a ser un icono
    if (emailRegex.test( valor || '' )) {
      return true;
    } else {
      return false;
    }
  }

  procederPago() {

    if( this.iniciarPago ) {
      this.iniciarPago = false;
    } else {
      ////console.log( this._reservacion_ );
      this.iniciarPago = true;
    }
  }


  validarDatos(): boolean{

    ////console.log( 'Tipo pago: ' +  this.prospectoReserva.Tipo );

    if( this._reservacion_.Nombre_Pasajero === '' ||  this._reservacion_.Apellido_Pasajero === '' || this._reservacion_.Email_Pasajero == '' || !this.emailCorrecto() || this._reservacion_.Nombre_Pasajero == '' || this._reservacion_.Telefono_Pasajero == '' || this._reservacion_.Telefono_Pasajero == null ||
    this._reservacion_.Desde_Txt === '' || this._reservacion_.Desde === null || this._reservacion_.Desde_Txt === undefined || this._reservacion_.Desde === undefined  || this._reservacion_.Pais_Pasajero === '' || this._reservacion_.Pais_Pasajero === null || this._reservacion_.Numero_Cuarto == null)
    {
          //////console.log( 'Algo mal en datos personales importantes' )
          return false;
    }

    else if( this.requiereFactura == 'SI' && ( this._reservacion_.Correo_Factura === ''|| this._reservacion_.Rfc_Factura === '' || this._reservacion_.Cfdi_Factura === '' || this._reservacion_.Nombre_Factura === '' || this._reservacion_.Direccion_Factura === '' ||  this._reservacion_.Ciudad_Factura == '' || this._reservacion_.Estado_Factura == '' || this._reservacion_.Codigo_Postal == '' || this._reservacion_.Codigo_Postal == null) )
    {
      //////console.log('Algo mal en los campos de facturacion')
      return false;
    }

    else
    {
      //////console.log('Excelente todo bien 10/10');
      return true;
    }

  }


  cargarLoading( event: any ){
    this.localData.isLoading = event;
  }

  guardarReservacion( _respuestapaypal_: respPagoPaypal ) {

    this._reservacion_.IdOrden = _respuestapaypal_.idOrden;
    this._reservacion_.FechaOrden = _respuestapaypal_.fechaOrden;
    this._reservacion_.StatusOrden = _respuestapaypal_.statusOrden ;
    this._reservacion_.EmailPayer = _respuestapaypal_.emailPayer ;
    this._reservacion_.CiudadCodePayer = _respuestapaypal_.ciudadCodePayer ;
    this._reservacion_.NombrePayer = _respuestapaypal_.nombrePayer ;
    this._reservacion_.IdMerchant = _respuestapaypal_.idMerchant ;
    this._reservacion_.StatusCaptura = _respuestapaypal_.statusCaptura ;
    this._reservacion_.IdCaptura = _respuestapaypal_.idCaptura ;

    this._reservacion_.FormaPago = 'PAYPAL';
    this._reservacion_.MetodoPago = 'PAYPAL';
    this._reservacion_.LugarPago = 'EITUR.MX';
    this._reservacion_.LugarReserva = 'EITUR.MX';


    if( _respuestapaypal_.ok && (_respuestapaypal_.idMerchant != null || _respuestapaypal_.idMerchant != '' || _respuestapaypal_.idMerchant != undefined) ) {

      this.externalData.postAddReservacion( this._reservacion_ ).subscribe( (data: any) => {

        this.subirScroll();

        if( data.ok ) {

            Swal.fire({
              title: 'Correct',
              html: `<p style="font-size:18px;text-align:center">${data.message}</p><p style="font-size:15px; color: red"> ${ data.messageDos } </p>`,
              icon: 'success',
              confirmButtonText: 'Acept',
              confirmButtonColor: 'green'
            }).then((result) => {
              if (result.value) {


                location.reload();

              }
            });

        } else {

          Swal.fire('Error', data.message , 'success');

        }

      });


    } else {
      this.subirScroll();
      Swal.fire('Error', _respuestapaypal_.respuesta , 'error')
    }

  }

  guardarParaPagoLlegada() {

    this.localData.isLoading = true;

    this._reservacion_.IdOrden = '';
    this._reservacion_.FechaOrden = '';
    this._reservacion_.StatusOrden = '';
    this._reservacion_.EmailPayer = '';
    this._reservacion_.CiudadCodePayer = '';
    this._reservacion_.NombrePayer = '';
    this._reservacion_.IdMerchant = '';
    this._reservacion_.StatusCaptura = 'PENDIENTE';
    this._reservacion_.IdCaptura = '';


    this._reservacion_.LugarPago = 'RECEPCION';
    this._reservacion_.LugarReserva = 'EITUR.MX';
    this._reservacion_.Hora_Vuelo_Salida = '07:00';



      this.externalData.postAddReservacion( this._reservacion_ ).subscribe( (data: any) => {

        this.subirScroll();

        if( data.ok ) {

            Swal.fire({
              title: 'Correct',
              html: `<p style="font-size:18px;text-align:center">${data.message}</p><p style="font-size:15px; color: red"> ${ data.messageDos } </p>`,
              icon: 'success',
              confirmButtonText: 'Acept',
              confirmButtonColor: 'green'
            }).then((result) => {
              if (result.value) {


                location.reload();

              }
            });

        } else {

          Swal.fire('Error', data.message , 'success');

        }

      });

  }


  subirScroll() {
    this.localData.isLoading = true;
    window.scroll(0,0);
    this.ocultarLoading();
  }

  ocultarLoading() {
    setTimeout(() => {
      this.localData.isLoading = false;
    },2000);
  }

  obtenerPaises() {
    this.paises = this.paises$.subscribe( (datos: any) => {
      console.log('Corriendo paises');
      console.log(datos);
      this.paises = datos.data;
    });
  }

}
