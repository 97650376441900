import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ExternalDataServiceService } from 'src/app/services/external-data-service.service';

export class Cupon {
  public Codigo?: string;
  public Status?: string;
  public Descripcion?: string;
  public Porcentaje?: string;
}

@Component({
  selector: 'app-input-cupon-reserva',
  templateUrl: './input-cupon-reserva.component.html',
  styleUrls: ['./input-cupon-reserva.component.css']
})
export class InputCuponReservaComponent implements OnInit {
  
  @Output() datosCodigo = new EventEmitter<Cupon>();

  viewError: boolean = false;
  cupon_valido: boolean = false;

  @Input() fechaReserva: string = '';
  @Input() txtDESCODE: string = '';
  @Input() txtIPTCODE: string = '';
  @Input() txtBTNCODE: string = '';
  @Input() txtCODERRO: string = '';
  @Input() txtCOD1COR: string = '';
  @Input() txtCOD2COR: string = '';

  _cupon: Cupon;

  constructor( private externalData: ExternalDataServiceService ) { 
    this._cupon = new Cupon();
  }

  ngOnInit(): void {
  }

  aplicarCupon() {
    
    this.externalData.postGetDatosCupon( this.fechaReserva, this._cupon.Codigo! ).subscribe( (datos: any) => {
      
      if( datos.ok ) {
        this.viewError = false;
        this._cupon.Codigo = datos.data[0].Codigo;
        this._cupon.Descripcion = datos.data[0].Descripcion;
        this._cupon.Porcentaje = datos.data[0].Porcentaje;
        this._cupon.Status = datos.data[0].Status;
        this.datosCodigo.emit(this._cupon);
        this.cupon_valido = true;
      } else {
        this._cupon.Codigo = '';
        this._cupon.Descripcion = '';
        this._cupon.Porcentaje = '0';
        this._cupon.Status = '';
        this.viewError = true;
        this.cupon_valido = false;
      }
      
  });

    

  }

  
}
