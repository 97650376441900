<br><br>
<table class="table table-bordered">
    <thead>
        <tr style="text-align: center; font-size: 20px; background: #00659f">
            <th colspan="4" >{{ txtTabla.BENEFICIO }}</th>
        </tr>
        <tr style="text-align: center; font-size: 20px; background: #23a9e1">
          <th scope="col">{{ txtTabla.BENEFICIO }}</th>
          <th scope="col">{{ txtTabla.SENCILLO }}</th>
          <th scope="col">{{ txtTabla.PREMIUM }}</th>
          <th scope="col">{{ txtTabla.DETALLE }}</th>
        </tr>
    </thead>
    <tbody style="text-align: center;">

        <tr>
          <th>{{ txtTabla.SERPRI }}</th>
          <td></td>
          <td><span class="material-icons" style="color: green;font-size: 18px;">check</span></td>
          <td>{{ txtTabla.PERSONA }}</td>
        </tr>

        <tr>
            <th>{{ txtTabla.AMECOR }}</th>
            <td></td>
            <td><span class="material-icons" style="color: green;font-size: 18px;">check</span></td>
            <td *ngIf="nameTour=='LDS Tulum y Chichen'||nameTour=='LDS Tulúm y Chichen'" >{{ txtTabla.AGSOCE }}</td>
            <td *ngIf="nameTour!='LDS Tulum y Chichen'&&nameTour!='LDS Tulúm y Chichen'" >{{ txtTabla.AGSOCEA }}</td>
        </tr>

        <tr>
            <th>{{ txtTabla.KIT19 }}</th>
            <td></td>
            <td><span class="material-icons" style="color: green;font-size: 18px;">check</span></td>
            <td>{{ txtTabla.TOADES }}</td>
        </tr>

        <tr>
            <th>{{ txtTabla.TIEMCONV }}</th>
            <td></td>
            <td><span class="material-icons" style="color: green;font-size: 18px;">check</span></td>
            <td>{{ txtTabla.CORTES }}</td>
        </tr>

        <tr>
            <th>{{ txtTabla.WIFI }}</th>
            <td></td>
            <td><span class="material-icons" style="color: green;font-size: 18px;">check</span></td>
            <td>{{ txtTabla.WIFI }}</td>
        </tr>
        {{ nameTour }}
    </tbody>
</table>
